import React, { useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	DialogTitle,
	Zoom,
	TextField,
	Autocomplete,
} from '@mui/material';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { CloseRounded, BorderColorRounded } from '@mui/icons-material/';
import convertCountryName from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../components/LoadingDots';
import { Line } from '../../../../styles';

import { usePO } from '../../../../utils/POContext';
import { OfferToolTip } from '../../../../helpers';
import { apiResponse } from '../../../../interfaces';
import { ClientType, FormErrors } from '../../../../interfaces/Clients';
import { updateClientInfo } from '../../../../services/client';
import { useSnackbar } from 'notistack';
import FormDivider from '../../../../components/FormDivider';
import { makeStyles } from '@mui/styles';

convertCountryName.registerLocale(
	// eslint-disable-next-line @typescript-eslint/no-var-requires
	require('i18n-iso-countries/langs/pt.json')
);

interface Props {
	rowData: ClientType;
	refresh: () => void;
	availableClients: ClientType[];
}

const ClientEditForm: React.FC<Props> = ({
	rowData,
	refresh,
	availableClients,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = React.useState(false);
	const [parentCompanyName, setParentCompanyName] = useState<
		ClientType | undefined
	>(undefined);
	const [resetParent, setResetParent] = useState<number>(0);

	const [formErrors, setFormErrors] = useState<FormErrors>({});

	const initialFormData = {
		name: rowData?.name || '',
		parent: rowData?.parent || '',
		clientID: rowData?.clientID || '',
		description: rowData?.description || '',
		ISactive: rowData?.ISactive || false,
		created: rowData?.created || '',
		id: rowData?.id || '',
		logo: rowData?.logo || '',
		email: rowData?.email || '',
		referenceCode: rowData?.referenceCode || '',
		BIID: rowData?.BIID || '',
	};

	const [formData, setFormData] = useState<ClientType>(initialFormData);

	const useStyles = makeStyles(() => ({
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		noOptions: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
	}));

	const classes = useStyles();

	const handleClose = () => {
		setOpen(false);
		setResetParent(resetParent + 1);
	};

	const handleOpen = () => {
		setOpen(true);
		setFormData(initialFormData);
	};

	const handleCommitInfo = async () => {
		const clientPayload = {
			name: formData.name,
			description: formData.description,
			BIID: formData.BIID,
			parent: formData.parent,
		};
		setLoading(true);
		const response: apiResponse | undefined = await updateClientInfo(
			rowData.id,
			clientPayload
		);
		if (response && response.status) {
			enqueueSnackbar(t('Clientes.Cliente atualizado com sucesso'), {
				variant: 'success',
			});
			refresh();
		} else {
			enqueueSnackbar(t('Clientes.ERRO'), {
				variant: 'warning',
			});
		}
		setLoading(false);
		handleClose();
	};

	async function handleSubmit(formData: ClientType) {
		try {
			const schema = Yup.object().shape({
				name: Yup.string()
					.min(3, t('Clientes.O nome precisa ter no mínimo 3 caracteres'))
					.required(t('Clientes.O nome é obrigatório')),
			});
			await schema.validate(formData, {
				abortEarly: false,
			});
			// eslint-disable-next-line no-unused-expressions
			setFormErrors({});
			handleCommitInfo();
		} catch (err) {
			const validationErrors: FormErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error: Yup.ValidationError) => {
					validationErrors[error.path || ''] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	}

	const handleFormStyle = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const handleFormTitle = () => (
		<Typography
			noWrap
			style={{
				color: handleFormStyle(),
				fontSize: 20,
				fontWeight: 'bold',
				maxWidth: '90%',
			}}
		>
			{`${t('Clientes.Editar Cliente')}: ${rowData.name}`}
		</Typography>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: 5,
				}}
			>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Clientes.Cancelar')}
				</Button>
				{loading ? (
					<Loading width={90} height={35} loop />
				) : (
					<Button
						onClick={handleCommitInfo}
						variant="contained"
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{t('Clientes.Salvar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<div>
			<IconButton
				onClick={handleOpen}
				sx={{
					marginLeft: '5px',
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<OfferToolTip
					title={`${t('Clientes.Editar Cliente')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<BorderColorRounded
						style={{
							fontSize: 20,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</OfferToolTip>
			</IconButton>
			<Dialog
				open={open}
				scroll="body"
				disableEscapeKeyDown
				maxWidth="md"
				fullWidth
			>
				<DialogTitle
					style={{
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						color: '#FFFFFF',
						display: 'flex',
					}}
					id="draggable-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
						}}
					>
						{handleFormTitle()}

						<IconButton
							onClick={handleClose}
							style={{ marginRight: '-16px' }}
							disabled={loading}
							sx={{
								'&:hover': {
									backgroundColor: selectedTheme.hoverBackground,
								},
							}}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'main'
											? selectedTheme.primary
											: 'white',
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
				<DialogContent
					style={{
						padding: 22,
						width: '100%',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					}}
				>
					<Form
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
						placeholder=""
						onSubmit={handleSubmit}
						style={{
							width: '100%',
						}}
					>
						<FormDivider
							name={t('Clientes.Informações Básicas')}
							background={
								selectedTheme.id === 'dark'
									? selectedTheme.overlay6dp
									: selectedTheme.foreground
							}
						/>
						{rowData?.parent ? (
							<TextField
								variant="outlined"
								size="small"
								name="name"
								label={t('Clientes.Pai')}
								value={formData.parent}
								autoComplete="off"
								InputLabelProps={{
									shrink: true,
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
								}}
								InputProps={{
									readOnly: true,
									style: {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
								sx={{
									width: '100%',
									'.MuiFormHelperText-root.Mui-error': {
										mt: '0px',
										color: selectedTheme.error,
										borderColor: selectedTheme.error,
										position: 'absolute',
										bottom: '-21px',
									},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
								}}
							/>
						) : (
							<Autocomplete
								key={`parentInput-${resetParent}`}
								value={parentCompanyName}
								onChange={(_, newValue: ClientType | null) => {
									setParentCompanyName(newValue || undefined);
									setFormData({
										...formData,
										parent: newValue?.id || '',
									});
								}}
								classes={{
									option: classes.autoCompleteStyle,
									paper: classes.autoCompleteStyle,
									noOptions: classes.noOptions,
								}}
								handleHomeEndKeys
								disableClearable
								id="clientsParentField"
								noOptionsText={t('Clientes.Nenhuma opção disponível')}
								options={availableClients.filter(
									(client) => client.name !== rowData.name
								)}
								getOptionLabel={(option) => option.name}
								renderOption={(props, option) => (
									<li {...props}>{option.name}</li>
								)}
								isOptionEqualToValue={(
									option: ClientType,
									value: ClientType | undefined
								) => option.id === value?.id}
								style={{ width: '100%', marginTop: 10 }}
								renderInput={(params) => (
									<TextField
										{...params}
										name="parent"
										label={t('Clientes.Pai')}
										size="small"
										value={formData?.parent}
										placeholder={t(
											'Clientes.Escolha uma empresa da lista para adicionar como pai'
										)}
										variant="outlined"
										onChange={(e) => {
											setFormData({ ...formData, parent: e.target.value });
										}}
										InputLabelProps={{
											shrink: true,
											style: {
												color:
													selectedTheme.id === 'dark' &&
													selectedTheme.textColorMedium,
											},
										}}
										inputProps={{
											...params.inputProps,
											autoComplete: 'disable-autocomplete', // disable autocomplete and autofill
											style: {
												color:
													selectedTheme.id === 'dark'
														? selectedTheme?.textColorMedium
														: '',
											},
										}}
										sx={{
											'& .MuiInputBase-root': {
												background:
													selectedTheme.id === 'dark' &&
													selectedTheme?.overlay3dp,
												'& > fieldset': {
													borderColor:
														selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
												},
												':hover': {
													'& > fieldset': {
														borderColor:
															selectedTheme.id === 'dark' ? '#fff' : '#000',
													},
												},
											},
											'.MuiSvgIcon-root ': {
												fill:
													selectedTheme.id === 'dark' &&
													selectedTheme?.textColorMedium,
											},
										}}
									/>
								)}
							/>
						)}

						<TextField
							variant="outlined"
							size="small"
							name="name"
							value={formData?.name}
							error={!!formErrors?.name}
							helperText={formErrors?.name}
							onChange={(e) => {
								setFormData({ ...formData, name: e.target.value });
							}}
							label={t('Clientes.Nome')}
							autoComplete="off"
							InputLabelProps={{
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							InputProps={{
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							sx={{
								width: '100%',
								marginTop: '25px',
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
						/>
						<TextField
							variant="outlined"
							size="small"
							name="BIID"
							value={formData?.BIID}
							onChange={(e) => {
								setFormData({ ...formData, BIID: e.target.value });
							}}
							label={t('Clientes.Relatório Superset')}
							autoComplete="off"
							InputLabelProps={{
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							InputProps={{
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorMedium
											: '',
								},
							}}
							sx={{
								width: '100%',
								marginTop: '25px',
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
						/>
						<TextField
							variant="outlined"
							multiline
							rows="2"
							name="description"
							value={formData?.description}
							error={!!formErrors?.description}
							helperText={formErrors?.description}
							onChange={(e) => {
								setFormData({ ...formData, description: e.target.value });
							}}
							label={t('Clientes.Descrição')}
							autoComplete="off"
							InputLabelProps={{
								shrink: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							InputProps={{
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorMedium
											: '',
								},
							}}
							sx={{
								width: '100%',
								marginTop: '25px',
								'& .MuiInputBase-root': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							}}
						/>
					</Form>
				</DialogContent>
				{renderFooter()}
			</Dialog>
		</div>
	);
};

export default ClientEditForm;
