import React from 'react';
import { IconButton, Zoom } from '@mui/material';
import { FindInPage } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import { OfferToolTip } from '../../../../../helpers';
import { usePO } from '../../../../../utils/POContext';
interface Props {
	rowData: any;
	setOpen: () => void;
	setRowData: (rowData) => void;
}

const CallDialogDetails: React.FC<Props> = ({
	rowData,
	setOpen,
	setRowData,
}: Props) => {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();

	const handleDisableButton = () => {
		setRowData(rowData);
		setOpen();
	};

	const renderDetailsButton = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Buscas Salvas.Detalhes da busca')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={handleDisableButton}
				size="small"
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<FindInPage
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.disableBackground,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return renderDetailsButton();
};

export default CallDialogDetails;
