import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OfferTable from '../../components/OfferTable';
import { Box, IconButton, Typography, Zoom } from '@mui/material';
import {
	Cached,
	FirstPage,
	GavelRounded,
	KeyboardArrowLeft,
	KeyboardArrowRight,
	LastPage,
	Share,
} from '@mui/icons-material/';
import EntityFormModal from './EntityFormModal';
import TagSelector from '../../components/TagSelector';
import {
	getAppraisals,
	getAuthorsOnEntity,
	getEntities,
	getEntityById,
} from '../../services/entities';
import { enqueueSnackbar } from 'notistack';
import { OfferToolTip, formatPhoneNumber, statesUf } from '../../helpers';
import UnbindAuthorDialog from './UnbindAuthorDialog';
import OpenAuthorCard from './OpenAuthorCard';
import { Line } from '../../styles';
import { usePO } from '../../utils/POContext';
import TableSearch from '../../components/TableSearch';
import { EntityType, SearchParameter } from '../../interfaces';
import { closeAllPanels } from '../../redux/slices/expansibleTableSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Unauthorized from '../Page401';

export interface EntityRowData {
	reportID: string;
	id: string;
	name: string;
	registration: string;
	registrationTypeID: string;
	email: string;
	address: string;
	addressNumber: number;
	zipCode: string;
	phoneNumber: React.JSX.Element | string;
	cityName: string;
	stateUf: React.JSX.Element | string;
	stateName: string;
	countryName: string;
	tags: string[];
	formattedTags: React.JSX.Element;
	attachments: string[];
	observation: string;
	created: string;
	updated: string;
	isExpansible: boolean;
}

const Entities: React.FC = () => {
	const { entityID } = useParams<{ entityID: string }>();
	const { selectedTheme, selectedClient, userData } = usePO();
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const [loading, setLoading] = useState(true);
	const [tableData, setTableData] = useState<EntityRowData[]>([]);
	const [tableLength, setTableLength] = useState(1);
	const [currentPage, setCurrentPage] = useState(0);
	const [currentPageChange, setCurrentPageChange] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1
	);
	const [displayCurrentPage, setDisplayCurrentPage] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [refreshTable, setRefreshTable] = useState(0);
	const [singleEntityData, setSingleEntityData] = useState<any>({});
	const [searchFieldsArray, setSearchFieldsArray] = useState<SearchParameter[]>(
		[]
	);

	const [appraisalEntityID, setAppraisalEntityID] = useState<any[]>([]);

	useEffect(() => {
		const fetchAppraisalEntityID = async () => {
			try {
				const response = await getAppraisals();
				if (response && response.data) {
					setAppraisalEntityID(response.data);
				}
			} catch (error) {
				enqueueSnackbar(t(`Entidades.Laudo não encontrado`), {
					variant: 'error',
				});
			}
		};

		fetchAppraisalEntityID();
	}, []);

	useEffect(() => {
		if (selectedClient?.id !== undefined) {
			setLoading(true);
			fetchData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		selectedClient,
		currentPageChange,
		rowsPerPage,
		refreshTable,
		searchText,
		searchFieldsArray,
	]);

	useEffect(() => {
		if (entityID) {
			fetchSingleEntityData(entityID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [entityID]);

	const fetchSingleEntityData = async (entityID: string) => {
		const normalizedEntityID = entityID.replace('entityID:', '');
		const result: any = await getEntityById(normalizedEntityID);
		if (result.success) {
			setSingleEntityData(result.data);
		} else {
			enqueueSnackbar(result.message, { variant: 'error' });
		}
	};

	const handleRefreshTable = async () => {
		setRefreshTable(refreshTable + 1);
	};

	const fetchData = async () => {
		setLoading(true);
		const searchPayload = {
			name: '',
			registration: '',
			registrationTypeID: '',
			email: '',
			address: '',
			addressNumber: null,
			zipCode: '',
			phoneNumber: '',
			cityName: '',
			stateName: '',
			countryName: '',
			tags: [],
			startDate: '',
			finalDate: '',
			itemsPerPage: rowsPerPage,
			page: currentPage + 1,
			orderField: 'updated',
			ordination: 'DESC',
		};
		if (searchFieldsArray.length > 0) {
			searchFieldsArray.forEach((fieldObj) => {
				if (fieldObj.value !== '') {
					searchPayload[fieldObj.field] = fieldObj.value;
				}
			});
		}
		const response: any = await getEntities(searchPayload);
		if (response.success === true) {
			if (response.data.hits.length > 0) {
				buildTableData(response.data.hits);
				setTableLength(response.data.totalHits);
				setDisplayCurrentPage(currentPage);
			} else {
				setLoading(false);
			}
		}
		if (response.success === false) {
			enqueueSnackbar(response.message, { variant: 'error' });
		}
		setLoading(false);
	};

	const formatTags = (tags: any) => {
		if (tags && tags.length > 0) {
			return <TagSelector displayOnly dataArray={tags} tagSize="small" />;
		} else return <Typography>-</Typography>;
	};

	const getSateUf = (state: string) => {
		if (!state) return '-';
		const foundState: any = statesUf.find((el) => el.name === state);
		return foundState.uf || '-';
	};

	const rowsPerPageOptions = [
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1,
		50,
		100,
	];

	const handleChangeRowsPerPage = (event) => {
		if (currentPage > 0) {
			setCurrentPage(0);
			setDisplayCurrentPage(0);
			setLoading(true);
			setRowsPerPage(event.target.value);
		}
		if (currentPage === 0) {
			setRowsPerPage(event.target.value);
		}
	};

	const handleChangePage = (event, newPage: React.SetStateAction<number>) => {
		dispatch(closeAllPanels());
		setLoading(true);
		setCurrentPage(newPage);
		setCurrentPageChange(currentPageChange + 1);
	};

	const handleKeyArrowStyle = (disableVerification) => {
		if (disableVerification) {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorDisable : '';
		} else {
			return selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '';
		}
	};

	function tablePaginationActions() {
		const handleFirstPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, 0);
		};

		const handleBackButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage - 1);
		};

		const handleNextButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(event, currentPage + 1);
		};

		const handleLastPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			handleChangePage(
				event,
				Math.max(0, Math.ceil(tableLength / rowsPerPage) - 1)
			);
		};
		return (
			<Box
				style={{
					flexShrink: 0,
					marginLeft: 30,
				}}
			>
				<Line>
					<IconButton
						onClick={handleFirstPageButtonClick}
						disabled={currentPage === 0}
						aria-label="first page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<FirstPage
							style={{ color: handleKeyArrowStyle(currentPage === 0) }}
						/>
					</IconButton>
					<IconButton
						onClick={handleBackButtonClick}
						disabled={currentPage === 0}
						aria-label="previous page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowLeft
							style={{ color: handleKeyArrowStyle(currentPage === 0) }}
						/>
					</IconButton>
					<IconButton
						onClick={handleNextButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="next page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<KeyboardArrowRight
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
					<IconButton
						onClick={handleLastPageButtonClick}
						disabled={currentPage >= Math.ceil(tableLength / rowsPerPage) - 1}
						aria-label="last page"
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<LastPage
							style={{
								color: handleKeyArrowStyle(
									currentPage >= Math.ceil(tableLength / rowsPerPage) - 1
								),
							}}
						/>
					</IconButton>
				</Line>
			</Box>
		);
	}

	const buildTableData = async (data: EntityType[]) => {
		if (data) {
			setTableData([]);
			const tempTableData: any = data.map((el: EntityType) => ({
				id: el.id,
				name: el.name,
				registration: el.registration || '-',
				registrationTypeID: el.registrationTypeID,
				email: el.email,
				address: el.address,
				addressNumber: el.addressNumber,
				zipCode: el.zipCode,
				phoneNumber: formatPhoneNumber(el.phoneNumber) || '-',
				cityName: el.cityName,
				stateUf: getSateUf(el.stateName),
				stateName: el.stateName,
				countryName: el.countryName,
				tags: el.tags,
				formattedTags: formatTags(el.tags),
				attachments: el.attachments,
				observation: el.observation,
				created: el.created,
				updated: el.updated,
				isExpansible: el.hasAuthorINentity,
				reportID: '',
			}));

			setTableData(tempTableData);
			setLoading(false);
		}
	};

	const expandedeDataFetch = async (rowData) => {
		const expandedResponse: any = await getAuthorsOnEntity(rowData.id);
		if (expandedResponse.success && expandedResponse.data.length > 0) {
			const tempTableData: any = [];
			await expandedResponse.data.map((el: any) => {
				const formatedAuthor = {
					id: el.id || '',
					entityID: rowData.id || '',
					name: el.name || '',
					platformINcountryID: el.platformINcountryID || '',
					permalink: el.permalink || '',
					created: el.created || '',
				};
				tempTableData.push(formatedAuthor);
				return null;
			});
			return tempTableData;
		}
	};

	const expansibleTableActions = [
		{
			component: OpenAuthorCard,
			props: {},
		},
		{
			component: UnbindAuthorDialog,
			props: { reloadTable: handleRefreshTable },
		},
	];

	const expandedColsData = [
		{
			columnTitle: t('Entidades.Id'),
			fieldName: 'id',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 100,
		},
		{
			columnTitle: t('Entidades.Nome'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 200,
		},
		{
			columnTitle: t('Entidades.Plataforma'),
			fieldName: 'platformINcountryID',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 150,
			dataType: 'platformINcountry',
		},
		{
			columnTitle: t('Entidades.Link externo'),
			fieldName: 'permalink',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 200,
			dataType: 'url',
		},
		{
			columnTitle: t('Entidades.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 120,
			dataType: 'date',
		},
	];

	const colsData = [
		{
			columnTitle: t('Entidades.Nome'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 0px',
			cellWidth: 200,
		},
		{
			columnTitle: t('Entidades.Número de registro'),
			fieldName: 'registration',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 0px',
			cellWidth: 50,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Entidades.Etiquetas'),
			fieldName: 'formattedTags',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 0px',
			cellWidth: 200,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Entidades.Localidade'),
			fieldName: 'stateUf',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 0px',
			cellWidth: 30,
		},
		{
			columnTitle: t('Entidades.Telefone'),
			fieldName: 'phoneNumber',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 0px',
			cellWidth: 100,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Entidades.Observações'),
			fieldName: 'observation',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 0px',
			cellWidth: 100,
		},
		{
			columnTitle: t('Entidades.Data'),
			fieldName: 'created',
			translateColumnData: false,
			alignTitle: 'center',
			marginTitle: '0 0 0 0px',
			cellWidth: 150,
			dataType: 'date',
		},
	];

	const TakeActions = (props) => {
		const isButtonEnabled = appraisalEntityID.some(
			(appraisal) => appraisal.entityID === props.rowData.id
		);

		return (
			<IconButton
				aria-label="taka"
				onClick={() => openEntityAppraisal(props.rowData.id)}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
				disabled={!isButtonEnabled}
			>
				<GavelRounded
					style={{
						fontSize: 19,
						color: !isButtonEnabled
							? selectedTheme.id === 'dark'
								? selectedTheme.textColorDisable
								: selectedTheme.disabled
							: selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: '',
					}}
				/>
			</IconButton>
		);
	};

	const openEntityAppraisal = (entityID: string) => {
		const newWindow = window.open(
			`${window.location.origin}/entity-appraisal/${entityID}`,
			'_blank',
			'noopener,noreferrer'
		);
		if (newWindow) newWindow.opener = null;
	};

	const ShareButton = (props) => (
		<IconButton
			aria-label="share-entity"
			onClick={() => shareEntity(props.rowData.id)}
			style={{ marginLeft: -3 }}
			sx={{
				'&:hover': {
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.primaryLight,
				},
			}}
		>
			<Share
				style={{
					fontSize: 18,
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			/>
		</IconButton>
	);

	const shareEntity = (entityID: string) => {
		const entityUrl = `${window.location.origin}/entityID/${entityID}`;
		navigator.clipboard.writeText(entityUrl);
		enqueueSnackbar(t('Entidades.Link copiado para a área de transferência'), {
			variant: 'success',
		});
	};

	const tableActions = [
		{
			component: EntityFormModal,
			props: { refreshTable: fetchData, isNewEntity: false },
		},
		{
			component: ShareButton,
			props: {},
		},
		{
			component: TakeActions,
			props: {},
		},
	];

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Buscas Salvas.Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				data-testid="refreshButton"
				onClick={handleRefreshTable}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Cached
					sx={{
						animation: loading ? '$spin 2s linear infinite' : 'normal',
						'@keyframes spin': loading
							? {
									'0%': {
										transform: 'rotate(360deg)',
									},
									'100%': {
										transform: 'rotate(0deg)',
									},
								}
							: null,
						zIndex: !loading ? 2 : 'auto',
					}}
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const fieldToSearch = [
		{ field: 'name', name: 'Nome' },
		{ field: 'registration', name: 'Número de registro' },
		{ field: 'email', name: 'Email' },
	];

	return userData.permissions?.indexOf('read:users:roles') !== -1 ? (
		<>
			{singleEntityData?.id && (
				<EntityFormModal
					rowData={singleEntityData}
					refreshTable={fetchData}
					isNewEntity={false}
					openEntityFromUrl={true}
				/>
			)}
			<OfferTable
				customLeftFooterComponent={renderReloadTableData()}
				colsData={colsData}
				customCurrentPage={displayCurrentPage}
				CustomHeaderComponent={EntityFormModal}
				CustomHeaderComponentProps={{
					refreshTable: fetchData,
					isNewEntity: true,
				}}
				customSearch={
					<TableSearch
						setSearchText={setSearchText}
						setSearchFieldsArray={setSearchFieldsArray}
						searchFieldsArray={searchFieldsArray}
						customTooltip="Busca nos campos da tabela"
						fields={fieldToSearch}
					/>
				}
				customRowsPerPage={rowsPerPage}
				customRowsPerPageOptions={rowsPerPageOptions}
				customTableLength={tableLength}
				customTablePagination={tablePaginationActions}
				denseText
				expandedColsData={expandedColsData}
				expandedDataFetch={expandedeDataFetch}
				expandedNoDataMessage={t('Entidades.Nenhum autor vinculado')}
				expandedTableActions={expansibleTableActions}
				expansibleRows
				handleChangeCustomRowsPerPage={handleChangeRowsPerPage}
				loading={loading}
				tableActions={tableActions}
				tableData={tableData}
				tableFooter
				windowHeader
				windowTitle={t('Entidades.Entidades')}
			/>
		</>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};
export default Entities;
