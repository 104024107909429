import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Typography,
	Zoom,
} from '@mui/material';
import { api, isAxiosError } from '../../../../utils/api';
import { Line } from '../../../../styles';
import { OfferToolTip } from '../../../../helpers';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material/';
import LoadingDots from '../../../../components/LoadingDots';
import { enqueueSnackbar } from 'notistack';
import { ErrorResponse } from '../../../../interfaces';
interface Props {
	rowData: any;
	refresh: () => void;
}

const DeleteINCountryDialog: React.FC<Props> = ({
	rowData,
	refresh,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleDeleteINcountry = async () => {
		setLoading(true);
		try {
			const deleteINcountry = await api.delete(
				`/platformINcountry/${rowData?.inCountryID}`
			);
			setLoading(false);
			enqueueSnackbar(deleteINcountry.data.detail, { variant: 'success' });
			handleClose();
			refresh();
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse?.data?.detail, { variant: 'error' });
				}
			}
		} finally {
			handleClose();
			refresh();
		}
	};

	const renderAction = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Plataformas.Excluir País')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setOpen(true)}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Delete
					style={{
						fontSize: 23,
						color: selectedTheme.error,
						marginRight: -2,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderDialogContent = () => (
		<DialogContent
			style={{
				padding: '20px 30px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground,
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
			}}
		>
			<Line>
				<Typography variant="h6">
					{`${t('Buscas.Tabs.Plataformas.Deseja excluir a busca')}`}:
				</Typography>
				<Typography variant="h6" style={{ marginLeft: 5 }}>
					{`${rowData?.name} - ${rowData?.country}`}
				</Typography>
				<Typography variant="h6">?</Typography>
			</Line>
		</DialogContent>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{`${t('Buscas.Tabs.Plataformas.Cancelar')}`}
				</Button>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						onClick={handleDeleteINcountry}
						style={{
							color:
								selectedTheme?.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{`${t('Buscas.Tabs.Plataformas.Excluir')}`}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			{renderAction()}
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
			>
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default DeleteINCountryDialog;
