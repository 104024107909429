import React, { useState } from 'react';
import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
	Zoom,
	createFilterOptions,
} from '@mui/material';
import { allCountries, OfferSwitch, OfferToolTip } from '../../../../helpers';
import { api, isAxiosError, setupRequestToken } from '../../../../utils/api';
import { Column, Line, RegularPanel } from '../../../../styles';
import { useAuth0 } from '../../../../react-auth0-spa';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import {
	AddCircleRounded,
	CloseRounded,
	Delete,
	LaunchOutlined,
} from '@mui/icons-material/';
import ConfirmPlatformDialog from './ConfirmPlatformDialog';
import FileCard from '../../../../components/FileCard';
import FormDivider from '../../../../components/FormDivider';
import FullSizeImageDialog from '../../../../components/FullSizeImageModal';
import LoadingDots from '../../../../components/LoadingDots';
import Panel from '../../../../components/Panel';
import { makeStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import {
	CountryType,
	ErrorResponse,
	PlatformOptionsType,
	PlatformType,
} from '../../../../interfaces';

interface CompanyType {
	inputValue?: string;
	name: string;
}

interface Props {
	refresh: () => void;
	availablePlatformsData: any;
	platformTypeOptions: PlatformOptionsType[];
}

const BuildPlatform: React.FC<Props> = ({
	refresh,
	availablePlatformsData,
	platformTypeOptions,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const { token } = useAuth0();
	setupRequestToken(api, token);
	const filter = createFilterOptions<CompanyType>();

	const [switchState, setSwitchState] = React.useState({
		mostrarTodos: false,
	});
	const [panelState, setPanelState] = React.useState(switchState.mostrarTodos);

	const [selectedPlatform, setSelectedPlatform] = useState<any>(null);
	const [selectedCountry, setSelectedCountry] = useState<any>(null);
	const [description, setDescription] = useState<any>('');
	const [uploadedFile, setUploadedFile] = useState<any>(null);

	const [formErrorPlatform, setFormErrorPlatform] = useState(false);
	const [formErrorCountry, setFormErrorCountry] = useState(false);
	const [openFileModal, setOpenFileModal] = useState(false);

	const [addType, setAddType] = useState<string>('');
	const [addInfoDialogOpen, setAddInfoDialogOpen] = useState<boolean>(false);

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = React.useState(false);
	const [selectPlatformType, setSelectPlatformType] = useState<string>('');
	const [formErrorPlatformType, setFormErrorPlatformType] =
		useState<boolean>(false);

	// eslint-disable-next-line @typescript-eslint/no-var-requires
	const countries = require('i18n-iso-countries');
	countries.registerLocale(
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		require('i18n-iso-countries/langs/pt.json')
	);

	const handleSelectPlatformChange = (event) => {
		setSelectPlatformType(event.target.value);
	};

	const handleClose = () => {
		setOpen(false);
		setSelectedPlatform(null);
		setUploadedFile(null);
		setSelectedCountry(null);
		setDescription('');
		setSelectPlatformType('');
		setAddType('');
		setTimeout(() => {
			setPanelState(false);
			setSwitchState({
				...switchState,
				mostrarTodos: false,
			});
		}, 200);
	};

	const sendPlatformData = async () => {
		try {
			setLoading(true);
			const formData = new FormData();
			if (uploadedFile && uploadedFile.file) {
				formData.append('uploadFile', uploadedFile.file, uploadedFile.name);
			} else {
				formData.append('uploadFile', '');
			}
			if (addType === 'newPlatform') {
				const buildPlatformResponse = await api.post(
					`/platforms?name=${selectedPlatform}&platformTypeID=${selectPlatformType}&description=${description}`,
					formData
				);
				enqueueSnackbar(t(buildPlatformResponse.data.detail), {
					variant: 'success',
				});
			}
			const platformINCountryPayload = {
				platformID: selectedPlatform.toUpperCase().split(' ').join('-'),
				countryID: `${countries.getAlpha3Code(selectedCountry.name, 'pt', {
					select: 'official',
				})}`,
				ISactive: true,
			};

			setTimeout(async () => {
				const buildPlatformINCountryResponse = await api.post(
					`/platformINcountry`,
					platformINCountryPayload
				);
				enqueueSnackbar(t(buildPlatformINCountryResponse.data.detail), {
					variant: 'success',
				});
				setLoading(false);
				handleClose();
				refresh();
			}, 1000);
		} catch (error) {
			setLoading(false);
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse?.data?.detail, { variant: 'error' });
				}
			}
		}
	};

	async function handleSubmit() {
		if (!selectedPlatform) {
			setFormErrorPlatform(true);
		}
		if (!selectedCountry?.name) {
			setFormErrorCountry(true);
		}
		if (!selectPlatformType) {
			setFormErrorPlatformType(true);
		}
		if (!uploadedFile) {
			enqueueSnackbar(
				t(
					'Buscas.Tabs.Plataformas.Por favor adicione uma logomarca a plataforma'
				),
				{ variant: 'warning' }
			);
		}

		if (selectedCountry?.name && selectedPlatform && uploadedFile) {
			setFormErrorPlatform(false);
			setFormErrorCountry(false);
			sendPlatformData();
		}
	}

	const handlePanelsSwitchChange = (event) => {
		setSwitchState({
			...switchState,
			[event.target.name]: event.target.checked,
		});
		setPanelState(event.target.checked);
	};

	const handleNewPlatform = () => {
		setAddType('newPlatform');
		setTimeout(() => {
			setAddInfoDialogOpen(true);
		}, 100);
	};

	const handleAddPlatformBranch = () => {
		setAddType('branch');
		setAddInfoDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setAddInfoDialogOpen(false);
	};

	const handleConfirmDialog = (type: string) => {
		setAddType(type);
		setAddInfoDialogOpen(false);
	};

	const handleThemeHeaderTitle = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};
	const handleSwitchChangeStyle = () => {
		if (switchState.mostrarTodos) {
			switch (selectedTheme.id) {
				case 'dark':
					return selectedTheme.textColorHigh;
				case 'main':
					return selectedTheme.primary;
				default:
					return selectedTheme.primary;
			}
		} else {
			switch (selectedTheme.id) {
				case 'dark':
					return selectedTheme.primaryDark;
				case 'main':
					return '';
				default:
					return;
			}
		}
	};

	const useStyles = makeStyles(() => ({
		autoCompleteStyles: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
	}));

	const classes = useStyles();

	const renderFooterButton = () => (
		<IconButton
			style={{ marginRight: '-15px', borderRadius: 5 }}
			onClick={() =>
				setTimeout(() => {
					setOpen(true);
				}, 400)
			}
			sx={{
				'&:hover': {
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.primaryLight,
				},
			}}
		>
			<Typography
				variant="subtitle1"
				style={{
					fontWeight: 'bold',
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primaryDark,
					margin: '0px 15px',
					marginTop: 2,
				}}
			>
				{t('Buscas.Tabs.Plataformas.Adicionar')}
			</Typography>
			<AddCircleRounded
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primaryDark,
					marginRight: 15,
				}}
			/>
		</IconButton>
	);

	const renderHeader = () => (
		<>
			<DialogTitle
				style={{
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					color: '#FFFFFF',
					display: 'flex',
				}}
				id="max-width-dialog-title"
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					<Typography
						noWrap
						style={{
							color: handleThemeHeaderTitle(),
							fontSize: 20,
							fontWeight: 'bold',
							maxWidth: '90%',
						}}
					>
						{t('Buscas.Tabs.Plataformas.Adicionar Plataforma')}
					</Typography>
					<IconButton
						onClick={handleClose}
						style={{ marginRight: '-16px' }}
						sx={{
							'&:hover': {
								backgroundColor:
									selectedTheme.id === 'dark' && selectedTheme.primaryLight,
							},
						}}
					>
						<CloseRounded
							style={{
								color: handleThemeHeaderTitle(),
							}}
						/>
					</IconButton>
				</Line>
			</DialogTitle>
		</>
	);

	const renderDialogContent = () => (
		<DialogContent
			style={{
				backgroundColor:
					selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : '',
				padding: '0px 0px',
				color:
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.primaryDark,
			}}
		>
			<Divider
				style={{
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.footerLine,
				}}
			/>
			<Divider
				style={{
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.footerLine,
				}}
			/>
			<RegularPanel
				style={{
					backgroundColor:
						selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
				}}
			>
				<Panel
					noMargin
					open
					title={t('Buscas.Tabs.Plataformas.Informações Básicas')}
				>
					<Column>
						<Autocomplete
							freeSolo
							value={selectedPlatform}
							onChange={(event, newValue) => {
								if (typeof newValue === 'string') {
									setSelectedPlatform(newValue);
									handleNewPlatform();
								} else if (newValue && newValue.inputValue) {
									setSelectedPlatform(newValue.inputValue);
									handleNewPlatform();
								} else {
									setSelectedPlatform(newValue?.name);
									handleAddPlatformBranch();
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								if (params.inputValue !== '') {
									filtered.push({
										inputValue: params.inputValue,
										name: `${t(
											'Buscas.Tabs.Plataformas.Adicionar nova plataforma'
										)}: "${params.inputValue}"`,
									});
								}
								return filtered;
							}}
							options={availablePlatformsData}
							getOptionLabel={(option) => {
								if (typeof option === 'string') {
									return option;
								}
								if (option.inputValue) {
									return option.inputValue;
								}
								return option.name || '';
							}}
							classes={{
								option: classes.autoCompleteStyles,
								root: classes.autoCompleteStyles,
								paper: classes.autoCompleteStyles,
							}}
							renderOption={(props, option: PlatformType) => (
								<li {...props}>{option.name}</li>
							)}
							disableClearable
							style={{ width: '100%' }}
							renderInput={(params) => (
								<TextField
									{...params}
									error={!!formErrorPlatform}
									helperText={
										formErrorPlatform
											? t(
													'Buscas.Tabs.Plataformas.Selecione ao menos uma plataforma'
												)
											: null
									}
									value={selectedPlatform}
									name="selectedPlatform"
									label={t('Buscas.Tabs.Plataformas.Nome da plataforma')}
									size="small"
									placeholder={t(
										'Buscas.Tabs.Plataformas.Digite o nome de uma nova plataforma ou escolha uma plataforma da lista para adicionar'
									)}
									variant="outlined"
									InputLabelProps={{ shrink: true }}
									inputProps={{
										...params.inputProps,
										style: {
											color:
												selectedTheme.id === 'dark'
													? selectedTheme?.textColorMedium
													: '',
										},
									}}
									sx={{
										'& .MuiFormLabel-root': {
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
										'& .MuiInputBase-root': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
											},
											':hover': {
												'& > fieldset': {
													borderColor:
														selectedTheme.id === 'dark' ? '#fff' : '#000',
												},
											},
										},
										'.MuiSvgIcon-root ': {
											fill:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									}}
								/>
							)}
						/>
						<Autocomplete
							classes={{
								option: classes.autoCompleteStyles,
								root: classes.autoCompleteStyles,
								paper: classes.autoCompleteStyles,
							}}
							style={{ width: '100%', marginTop: 20 }}
							value={selectedCountry}
							options={allCountries}
							onChange={(event, newValue) => {
								setSelectedCountry(newValue);
							}}
							autoComplete
							autoSelect
							getOptionLabel={(option) => option.name || ''}
							isOptionEqualToValue={(option, value) =>
								option.name === value.name
							}
							renderOption={(props, option: CountryType) => (
								<li {...props}>{option.name}</li>
							)}
							disableClearable
							renderInput={(params) => (
								<TextField
									{...params}
									error={!!formErrorCountry}
									helperText={
										formErrorCountry
											? t('Buscas.Tabs.Plataformas.Selecione ao menos um país')
											: null
									}
									value={selectedCountry}
									name="selectedCountry"
									placeholder={t(
										'Buscas.Tabs.Plataformas.Escolha um país da lista para adicionar'
									)}
									size="small"
									label={t('Buscas.Tabs.Plataformas.País')}
									variant="outlined"
									InputLabelProps={{ shrink: true }}
									inputProps={{
										...params.inputProps,
										style: {
											color:
												selectedTheme.id === 'dark'
													? selectedTheme?.textColorMedium
													: '',
										},
										autoComplete: 'new-password',
									}}
									sx={{
										'& .MuiFormLabel-root': {
											color:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
										'& .MuiInputBase-root': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
											},
											':hover': {
												'& > fieldset': {
													borderColor:
														selectedTheme.id === 'dark' ? '#fff' : '#000',
												},
											},
										},
										'.MuiSvgIcon-root ': {
											fill:
												selectedTheme.id === 'dark' &&
												selectedTheme?.textColorMedium,
										},
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									}}
								/>
							)}
						/>
						<FormControl
							size="small"
							fullWidth
							sx={{
								width: '100%',
								marginTop: '15px',
								'.MuiOutlinedInput-notchedOutline': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								'&:hover .MuiOutlinedInput-notchedOutline': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							}}
						>
							<InputLabel
								sx={{
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								}}
							>
								{t('Buscas.Tabs.Plataformas.Tipo da plataforma')}
							</InputLabel>
							<Select
								label={t('Buscas.Tabs.Plataformas.Tipo da plataforma')}
								name="platformTypeID"
								value={selectPlatformType}
								onChange={handleSelectPlatformChange}
								error={!!formErrorPlatformType}
								sx={{
									'&:hover .MuiOutlinedInput-notchedOutline': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
									'.MuiSelect-select': {
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.overlay3dp
												: selectedTheme.foreground,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
										'&:hover': {
											background:
												selectedTheme.id === 'dark'
													? selectedTheme.overlay6dp
													: '',
										},
									},
									'.MuiSelect-icon': {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									},
									'& [aria-expanded=true]': {
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.overlay6dp
												: '',
									},
								}}
								inputProps={{
									MenuProps: {
										PaperProps: {
											sx: {
												background:
													selectedTheme.id === 'dark'
														? selectedTheme.overlay3dp
														: selectedTheme.foreground,
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: '',
											},
										},
									},
								}}
							>
								{platformTypeOptions.map((option: any) => (
									<MenuItem key={option?.id} value={option?.name}>
										{option.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormDivider
							name={t('Buscas.Tabs.Plataformas.Descrição')}
							background={
								selectedTheme.id === 'dark' && selectedTheme.overlay6dp
							}
						/>
						<TextField
							name="description"
							value={description}
							variant="outlined"
							multiline
							rows="2"
							autoComplete="off"
							onChange={(event) => {
								setDescription(event.target.value);
							}}
							inputProps={{
								style: {
									color:
										selectedTheme.id === 'dark'
											? selectedTheme?.textColorMedium
											: '',
								},
							}}
							sx={{
								'& .MuiOutlinedInput-root': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								width: '100%',
								'& .MuiInputBase-root': {
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
							}}
						/>
					</Column>
				</Panel>
			</RegularPanel>
			<RegularPanel
				style={{
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				}}
			>
				<Panel
					open={panelState}
					title={t('Buscas.Tabs.Plataformas.Logomarca')}
					disabled={addType === 'branch' ? true : false}
				>
					<Line style={{ width: '100%', color: 'red' }}>
						<FileCard
							setUploadedFile={setUploadedFile}
							customMessage={
								uploadedFile
									? uploadedFile?.name
									: t(
											'Buscas.Tabs.Plataformas.Clique no ícone ou arraste um arquivo para cá'
										)
							}
							cardActions
							actions1={actionElement1()}
							actions2={actionElement2()}
						/>
						<FullSizeImageDialog
							open={openFileModal}
							setOpen={setOpenFileModal}
							imageIndexToShow={0}
							images={uploadedFile === null ? '' : uploadedFile?.preview}
						/>
					</Line>
				</Panel>
			</RegularPanel>
		</DialogContent>
	);

	const actionElement1 = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Plataformas.Deletar arquivo')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setUploadedFile(null)}
				disabled={uploadedFile === null}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Delete
					style={{
						fontSize: 23,
						color: selectedTheme.error,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const actionElement2 = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Plataformas.Visualizar arquivo')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setOpenFileModal(true)}
				disabled={uploadedFile === null}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<LaunchOutlined
					style={{
						fontSize: 23,
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.disableBackground,
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				padding: '10px 15px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<FormControlLabel
					control={
						<OfferSwitch
							size="small"
							checked={switchState.mostrarTodos}
							onChange={handlePanelsSwitchChange}
							style={{ color: handleSwitchChangeStyle() }}
							name="mostrarTodos"
							inputProps={{ 'aria-label': 'show all checkbox' }}
						/>
					}
					label={
						<Typography
							variant="subtitle2"
							style={{
								paddingLeft: 5,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							}}
						>
							{t('Buscas.Tabs.Plataformas.Expandir todos')}
						</Typography>
					}
					style={{ marginLeft: 5, marginTop: -1 }}
				/>
				{loading ? (
					<LoadingDots height={30} width={70} loop />
				) : (
					<Button
						type="button"
						size="small"
						onClick={handleSubmit}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.primaryDark,
						}}
					>
						{t('Buscas.Tabs.Plataformas.Adicionar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<>
			{renderFooterButton()}
			<Dialog
				open={open}
				aria-label="confirm-edit-dialog"
				fullWidth
				maxWidth="md"
				onClose={handleClose}
				scroll="body"
			>
				{renderHeader()}
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
			<ConfirmPlatformDialog
				setConfirm={() => handleConfirmDialog(addType)}
				closeDialog={handleCloseDialog}
				platformName={selectedPlatform}
				open={addInfoDialogOpen}
				addType={addType}
			/>
		</>
	);
};

export default BuildPlatform;
