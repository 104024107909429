import React, { useEffect, useState } from 'react';
import { Checkbox, Typography, Button } from '@mui/material';
import { Info } from '@mui/icons-material/';
import { DataSearch } from '@appbaseio/reactivesearch';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../styles';
import { OfferToolTip } from '../../../helpers';
import { usePO } from '../../../utils/POContext';
import FormDivider from '../../FormDivider';
import { useDispatch, useSelector } from 'react-redux';
import { updateClassificationsFilters } from '../../../redux/slices/filterSlice';
import LoadingDots from 'src/components/LoadingDots';

interface Props {
	checkboxDenouncementsItemsField?: boolean;
	checkboxTagIDItemsField?: boolean;
	checkboxAllowedItemsField?: boolean;
}

const ViewDenouncementItems: React.FC<Props> = ({
	checkboxDenouncementsItemsField,
	checkboxTagIDItemsField,
	checkboxAllowedItemsField,
}) => {
	const { t } = useTranslation();
	const { selectedClient, selectedTheme, userData } = usePO();
	const dispatch = useDispatch();
	const filtersRedux = useSelector((state: any) => state.filters);

	const [isAllowedItemsVisible, setIsAllowedItemsVisible] = useState(false);
	const [isTagIDItemsVisible, setIsTagIDItemsVisible] = useState(false);
	const [isDenouncementItemsVisible, setIsDenouncementItemsVisible] =
		useState(false);

	const [appliedFilters, setAppliedFilters] = useState({
		viewDenouncementItems:
			filtersRedux.classifications.defaultFilters.viewDenouncementItems,
	});
	const [hasChanges, setHasChanges] = useState(false);
	const [isFiltering, setIsFiltering] = useState(false);

	const hasFiltersChanged = () => {
		return (
			isAllowedItemsVisible !==
				filtersRedux.classifications.defaultFilters.viewAllowedItems ||
			isTagIDItemsVisible !==
				filtersRedux.classifications.defaultFilters.viewTagIDItems ||
			isDenouncementItemsVisible !==
				filtersRedux.classifications.defaultFilters.viewDenouncementItems
		);
	};

	useEffect(() => {
		setHasChanges(hasFiltersChanged());
	}, [isAllowedItemsVisible, isTagIDItemsVisible, isDenouncementItemsVisible]);

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	useEffect(() => {
		setIsAllowedItemsVisible(
			filtersRedux.classifications.defaultFilters.viewAllowedItems
		);
		setIsTagIDItemsVisible(
			filtersRedux.classifications.defaultFilters.viewTagIDItems
		);
		setIsDenouncementItemsVisible(
			filtersRedux.classifications.defaultFilters.viewDenouncementItems
		);
	}, [filtersRedux.classifications.defaultFilters]);

	const handleApplyFilters = async () => {
		setIsFiltering(true);

		try {
			await new Promise((resolve) => setTimeout(resolve, 1000));
			dispatch(
				updateClassificationsFilters({
					filterName: 'defaultFilters',
					filterData: {
						...filtersRedux.classifications.defaultFilters,
						viewAllowedItems: isAllowedItemsVisible,
						viewTagIDItems: isTagIDItemsVisible,
						viewDenouncementItems: isDenouncementItemsVisible,
						viewDenouncements: isDenouncementItemsVisible
							? selectedClient?.id
							: '',
					},
				})
			);

			setAppliedFilters({ viewDenouncementItems: isDenouncementItemsVisible });
			setHasChanges(false);
		} catch (error) {
			console.error('Erro ao aplicar filtros:', error);
		} finally {
			setIsFiltering(false);
		}
	};

	const renderCheckbox = (checked, setChecked, label, tooltip, testId) => (
		<Line>
			<Checkbox
				data-testid={testId}
				aria-label="tour"
				checked={checked}
				onChange={() => setChecked(!checked)}
				color="primary"
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primaryDark,
				}}
			/>
			<Typography
				style={{
					color:
						selectedTheme.id === 'dark'
							? selectedTheme.textColorHigh
							: selectedTheme.primary,
				}}
			>
				{label}
			</Typography>
			<OfferToolTip title={tooltip}>
				<Info
					style={{
						marginLeft: 8,
						fontSize: 16,
						cursor: 'pointer',
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorMedium
								: selectedTheme.primaryDark,
					}}
				/>
			</OfferToolTip>
		</Line>
	);

	return (
		<>
			{userData?.permissions?.includes(
				'view:checkboxesfilters:classifications'
			) && (
				<>
					{(checkboxDenouncementsItemsField ||
						checkboxTagIDItemsField ||
						checkboxAllowedItemsField) && (
						<FormDivider
							name=""
							margin="8px 0"
							opacity={0.8}
							background={
								selectedTheme.id === 'dark'
									? '#303030'
									: selectedTheme.background
							}
						/>
					)}
					<Column style={{ rowGap: 5, paddingBlock: 5, margin: '-10px' }}>
						{checkboxAllowedItemsField &&
							renderCheckbox(
								isAllowedItemsVisible,
								setIsAllowedItemsVisible,
								t('FilterBar.Somente itens permitidos'),
								t('Classificações.Somente itens permitidos - informação'),
								'viewAllowedItems'
							)}
						{checkboxTagIDItemsField &&
							renderCheckbox(
								isTagIDItemsVisible,
								setIsTagIDItemsVisible,
								t('FilterBar.Somente itens de buscas'),
								t('Classificações.Somente itens de buscas - informação'),
								'viewTagIDItems'
							)}
						{checkboxDenouncementsItemsField &&
							renderCheckbox(
								isDenouncementItemsVisible,
								setIsDenouncementItemsVisible,
								t('Classificações.Itens denunciados deste cliente'),
								t(
									'Classificações.Ao ativar este filtro, somente as ofertas denunciadas pelo cliente ativo serão exibidas'
								),
								'viewDenouncementItems'
							)}
					</Column>

					{(checkboxDenouncementsItemsField ||
						checkboxTagIDItemsField ||
						checkboxAllowedItemsField) && (
						<Button
							variant="contained"
							color="primary"
							data-testid="applyFilters"
							onClick={handleApplyFilters}
							disabled={!hasChanges || isFiltering}
							style={{
								color:
									selectedTheme.id === 'dark'
										? hasChanges
											? ''
											: selectedTheme.textColorHigh
										: hasChanges
											? selectedTheme.textColorMedium
											: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
								opacity: hasChanges ? 1 : 0.6,
								cursor: hasChanges ? 'pointer' : 'not-allowed',
								pointerEvents: 'auto',
							}}
							title={t(
								'Classificações.Realize alguma alteração nos filtros para habilitar este botão.'
							)}
						>
							{isFiltering ? (
								<LoadingDots height={25} width={60} loop />
							) : (
								t('Classificações.Aplicar')
							)}
						</Button>
					)}

					<DataSearch
						componentId="denouncements"
						value={
							appliedFilters.viewDenouncementItems ? selectedClient?.id : ''
						}
						dataField="denouncementClientID.keyword"
						size={1000000}
						showIcon={false}
						autosuggest={false}
						highlight={false}
						queryFormat="and"
						debounce={1}
						filterLabel={t('Classificações.Itens denunciados deste cliente')}
						style={{ display: 'none' }}
					/>
				</>
			)}
		</>
	);
};

export default ViewDenouncementItems;
