import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OfferTable from '../../components/OfferTable';
import { useSnackbar } from 'notistack';
import UserBindModal from './components/UserBindModal';
import UserStatusChangeDialog from './components/UserStatusChangeDialog';
import UserRoleSelect from './components/UserRoleSelect';
import { availableUserRoles, getUsers } from '../../services/users';
import { Line } from '../../styles';
import Unauthorized from '../Page401';
import { usePO } from '../../utils/POContext';
import { OfferToolTip } from '../../helpers';
import {
	Cached,
	CancelRounded,
	CheckCircleRounded,
} from '@mui/icons-material/';
import { Zoom, IconButton, Typography } from '@mui/material';

const Usuarios: React.FC = () => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { selectedTheme, userData } = usePO();

	let usableRoles;

	const [loading, setLoading] = useState(true);
	const [tableData, setTableData] = useState<any[]>([]);

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line
	}, []);

	const fetchData = async () => {
		setLoading(true);
		const response: any = await getUsers();
		const availableRolesResponse: any = await availableUserRoles();
		if (availableRolesResponse.success === true) {
			usableRoles = availableRolesResponse.data;
		}
		if (response.success === true) {
			buildTableData(response.data);
		}
		if (response.success === false) {
			enqueueSnackbar(t(response.message), { variant: 'error' });
		}
		setLoading(false);
	};

	const colsData = [
		{
			columnTitle: t('Usuários.Nome'),
			fieldName: 'name',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 100,
			dataType: 'string',
		},

		{
			columnTitle: t('Usuários.E-mail'),
			fieldName: 'email',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 200,
			dataType: 'string',
		},
		{
			columnTitle: t('Usuários.Status'),
			fieldName: 'status',
			translateColumnData: false,
			alignTitle: 'left',
			marginTitle: '0 0 0 40px',
			cellWidth: 50,
			dataType: 'reactObject',
		},
		{
			columnTitle: t('Usuários.Papel'),
			fieldName: 'roles',
			translateColumnData: false,
			alignTitle: 'center',

			cellWidth: 200,
			dataType: 'reactObject',
		},
	];

	const buildTableData = async (data: any) => {
		if (data) {
			setTableData([]);
			const tempTableData: any = [];
			await data.map((el: any) => {
				const initialUserRole = usableRoles.find(
					(role) => role.name === el.roles[0]
				);
				const initialRoleDescription = initialUserRole
					? initialUserRole.description
					: '';

				const formattedUsers = {
					name: el.name,
					lastName: el.lastName,
					ISactive: el.ISactive,
					status: renderStatus(el.ISactive),
					email: el.email,
					roles: renderRoleSelect(el, initialUserRole, initialRoleDescription),
					id: el.id,
				};
				tempTableData.push(formattedUsers);
				return null;
			});
			setTableData(tempTableData);
		}
	};

	const renderRoleSelect = (
		rowData,
		initialUserRole,
		initialRoleDescription
	) => (
		<UserRoleSelect
			rowData={rowData}
			availableRoles={usableRoles}
			initialUserRole={initialUserRole}
			initialRoleDescription={initialRoleDescription}
			handleReload={fetchData}
		/>
	);

	const tableActions = [
		{
			component: UserBindModal,
			props: {},
		},
		{
			component: UserStatusChangeDialog,
			props: { refresh: fetchData },
		},
	];

	const renderStatus = (status: boolean) => {
		switch (status) {
			case true:
				return (
					<Line style={{ justifyContent: 'flex-start' }}>
						<CheckCircleRounded
							fontSize="small"
							style={{
								marginRight: 6,
								color: selectedTheme.success,
							}}
						/>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: '',
							}}
						>
							{t('Usuários.Aprovado')}
						</Typography>
					</Line>
				);
			case false:
				return (
					<Line
						style={{
							justifyContent: 'flex-start',
						}}
					>
						<CancelRounded
							fontSize="small"
							style={{
								marginRight: 6,
								color: selectedTheme.error,
							}}
						/>
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorMedium
										: '',
							}}
						>
							{t('Usuários.Desativado')}
						</Typography>
					</Line>
				);

			default:
				break;
		}
		return null;
	};

	const renderReloadTableData = () => (
		<OfferToolTip
			title={`${t('Buscas.Tabs.Buscas Salvas.Atualizar dados da tabela')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton data-testid="refreshButton" onClick={fetchData}>
				<Cached
					sx={{
						animation: loading ? '$spin 2s linear infinite' : 'normal',
						'@keyframes spin': loading
							? {
									'0%': {
										transform: 'rotate(360deg)',
									},
									'100%': {
										transform: 'rotate(0deg)',
									},
								}
							: null,
						zIndex: !loading ? 2 : 'auto',
					}}
					style={{
						fontSize: 22,
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return userData.permissions?.indexOf('read:users') !== -1 ? (
		<>
			<OfferTable
				denseText
				tableData={tableData}
				colsData={colsData}
				loading={loading}
				windowHeader
				windowTitle={`${t('Usuários.Gerenciamento de Usuários')}`}
				tableActions={tableActions}
				tableFooter
				customLeftFooterComponent={renderReloadTableData()}
			/>
		</>
	) : (
		<div style={{ marginLeft: '-75px' }}>
			<Unauthorized />
		</div>
	);
};

export default Usuarios;
