import React, { useState } from 'react';
import {
	IconButton,
	Zoom,
	Card,
	Divider,
	Typography,
	Box,
} from '@mui/material';
import { Backup } from '@mui/icons-material/';
import { makeStyles } from '@mui/styles';
import { useDropzone } from 'react-dropzone';
import filesize from 'filesize';
import { useTranslation } from 'react-i18next';
import { usePO } from '../../utils/POContext';
import { OfferToolTip } from '../../helpers';
import { Line } from '../../styles';
import LoadingDots from '../LoadingDots';
import { FileProps } from '../../interfaces';
import { enqueueSnackbar } from 'notistack';
import { DropContainer } from './styles';

interface Props {
	setUploadedFile: any;
	customMessage?: string;
	cardActions?: boolean;
	actions1?: any;
	actions2?: any;
	actions3?: any;
	disabled?: boolean;
}

const FileCard: React.FC<Props> = ({
	setUploadedFile,
	customMessage,
	cardActions,
	actions1,
	actions2,
	actions3,
	disabled = false,
}) => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const { selectedTheme } = usePO();

	const useStyles = makeStyles(() => ({
		root: {
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 5,
			height: 50,
			width: '100%',
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : '',
			display: 'flex',
			opacity: disabled ? 0.5 : 1,
			cursor: disabled ? 'not-allowed' : 'default',
		},
	}));

	const classes = useStyles();

	const onDrop = async (acceptedFiles) => {
		if (disabled) return;
		setLoading(true);
		const newUploadedFiles: FileProps = acceptedFiles.map((file: File) => ({
			file,
			name: file.name,
			readableSize: filesize(file.size),
			preview: URL.createObjectURL(file),
			progress: 0,
			uploaded: false,
			error: false,
			url: '',
		}));

		try {
			setUploadedFile(newUploadedFiles[0]);
			setLoading(false);
			enqueueSnackbar(t('FileCard.Arquivo inserido e renomeado com sucesso'), {
				variant: 'success',
			});
		} catch (error) {
			setLoading(false);
			enqueueSnackbar(t('FileCard.Erro ao inserir arquivo'), {
				variant: 'error',
			});
		}
	};

	const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
		useDropzone({
			multiple: false,
			accept: ['image/png', 'image/jpeg'],
			onDrop,
			noClick: true,
			disabled,
		});

	const renderDragMessage = () => {
		if (!isDragActive) {
			return (
				<>
					{loading ? (
						<Line style={{ justifyContent: 'center', padding: 20 }}>
							<LoadingDots width={110} height={60} key="loading" loop />
						</Line>
					) : (
						renderCard()
					)}
				</>
			);
		}
		if (isDragReject) {
			enqueueSnackbar(
				t('FileCard.Não é possível enviar este formato de arquivo'),
				{ variant: 'error' }
			);
		}
		return <>{renderCard()}</>;
	};

	const renderCard = () => (
		<Line
			style={{
				justifyContent: 'space-between',
				width: '100%',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay4dp : '',
			}}
		>
			<DropContainer {...getRootProps()} style={{ width: '78%' }}>
				<Line>
					<input {...getInputProps()} disabled={loading || disabled} />
					<Line
						style={{
							width: 60,
							justifyContent: 'flex-start',
							padding: '0px 3px',
						}}
					>
						<OfferToolTip
							title={`${t('FileCard.Adicionar Documentos')}`}
							aria-label="tooltip"
							arrow
							TransitionComponent={Zoom}
						>
							<IconButton
								onClick={open}
								sx={{
									'&:hover': {
										backgroundColor:
											selectedTheme.id === 'dark' && selectedTheme.primaryLight,
									},
								}}
								disabled={disabled}
							>
								<Backup
									style={{
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: selectedTheme.primary,
									}}
								/>
							</IconButton>
						</OfferToolTip>
					</Line>

					<Line>
						<Divider
							style={{ height: 28, marginLeft: -2 }}
							sx={{
								background:
									selectedTheme.id === 'dark' && selectedTheme.footerLine,
							}}
							orientation="vertical"
						/>
						<OfferToolTip
							title={`${customMessage}`}
							aria-label="inativo"
							enterDelay={700}
							enterNextDelay={700}
							arrow
							TransitionComponent={Zoom}
						>
							<Typography
								data-testid="FileCard"
								noWrap
								style={{
									margin: '0px  0px  0px 15px',
									maxWidth: 655,
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								}}
							>
								{customMessage || null}
							</Typography>
						</OfferToolTip>
					</Line>
				</Line>
			</DropContainer>
			{cardActions ? (
				<Line
					style={{
						width: '200px',
						justifyContent: 'right',
						marginRight: '15px',
					}}
				>
					<Box>{actions1 || null}</Box>
					<Box>{actions2 || null}</Box>
					<Box>{actions3 || null}</Box>
				</Line>
			) : null}
		</Line>
	);

	return <Card className={classes.root}>{renderDragMessage()}</Card>;
};

export default FileCard;
