import React, { useState } from 'react';
import {
	Button,
	ButtonGroup,
	CircularProgress,
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	Grow,
	Popper,
	Typography,
} from '@mui/material';
import { Error, ArrowDropDown, ArrowDropUp } from '@mui/icons-material/';
import { makeStyles } from '@mui/styles';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../../components/LoadingDots';
import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { MessageType } from '../../../../interfaces';
import SubmitedResumeDialog from '../../components/SubmitedResumeDialog';
import { sendNotificationsData } from '../../../../services/notifications/sendNotifications';
import { sendOffersToVerificator } from '../../../../services/notifications/sendToVerificator';
import { enqueueSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
	popButton: {
		'&:hover': {
			filter: 'brightness(95%)',
		},
	},
}));

interface Props {
	data: any;
	reloadTable: any;
	setData?: any;
	setTableData: (data: any) => void;
}

const ProcessItemsDialog: React.FC<Props> = ({
	data,
	reloadTable,
	setData,
	setTableData,
}: Props) => {
	const { t } = useTranslation();
	const {
		cursorMode,
		selectedClient,
		selectedTheme,
		updateCursorMode,
		userData,
	} = usePO();
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const classes = useStyles();

	useHotkeys('ctrl+alt+Enter', () => handleKeyShortcutProcess());
	useHotkeys('ctrl+alt+1', () => handleKeyShortcutSend());
	useHotkeys('ctrl+alt+2', () => handleKeyShortcutCheck());

	const [loading, setLoading] = React.useState(false);
	const [open, setOpen] = useState(false);
	const [openButton, setOpenButton] = React.useState(false);
	const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
	const [submitDataResults, setSubmitDataResults] = useState<any>();

	const applyButtomBackground = () => {
		if (!selectDisable() && data.length !== 0) {
			return selectedTheme?.primaryDark;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.overlay3dp
				: selectedTheme.foreground;
		}
	};

	const applyButtomColors = () => {
		if (!selectDisable() && data.length !== 0) {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorHigh
				: selectedTheme.foreground;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorDisable
				: '#aaaaaa';
		}
	};

	const handleKeyShortcutProcess = () => {
		if (data.length !== 0) {
			handleToggleButton();
		} else {
			enqueueSnackbar(t('Não há itens selecionados'), {
				variant: 'warning',
			});
		}
	};

	const handleKeyShortcutSend = () => {
		if (data.length !== 0) {
			handleOpenDialog();
		} else {
			enqueueSnackbar(t('Não há itens selecionados'), {
				variant: 'warning',
			});
		}
	};

	const handleKeyShortcutCheck = () => {
		if (data.length !== 0) {
			handleSendToVerificator();
		} else {
			enqueueSnackbar(t('Não há itens selecionados'), {
				variant: 'warning',
			});
		}
	};

	const handleToggleButton = () => {
		setOpenButton((prevOpen) => !prevOpen);
	};

	const handleCloseButton = (event: any) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	const handleClose = () => {
		setSubmitDataResults(undefined);
		setOpen(false);
	};

	const handleOpenDialog = () => {
		setOpen(true);
		setOpenButton(false);
	};

	const handleCloseAndReload = () => {
		setSubmitDataResults(undefined);
		setOpen(false);
		reloadTable();
	};

	const keepFailedSelected = () => {
		if (!submitDataResults?.good.length) return;

		submitDataResults.good.forEach((el) => {
			setData((itemsList) => itemsList.filter((item) => item.id !== el.id));
			setTableData((itemsList) =>
				itemsList.filter((item) => item.id !== el.id)
			);
		});
	};

	const handleCloseDialog = (clear?: boolean) => {
		if (clear) {
			setOpenSummaryDialog(false);
			handleCloseAndReload();
		}
		if (!clear) {
			keepFailedSelected();
			setOpenSummaryDialog(false);
			setOpen(false);
		}
	};

	/** Checks if user meets conditions to apply item */
	const selectDisable = () => {
		if (
			userData.roles?.indexOf('Desenvolvedor') !== -1 ||
			userData.roles?.indexOf('Administrador da ferramenta') !== -1 ||
			userData.roles?.indexOf('Analista de posts') !== -1
		) {
			if (data.length === 0) {
				return true;
			}
			return false;
		}
		return true;
	};

	const handleSnackbarMessage = (message: any, type: MessageType) => {
		enqueueSnackbar(message, { variant: type });
	};

	const prepareDataToSubmit = () => {
		// create chunked dataset to send to backend
		const chunkSize = 100;
		const chunks = new Set();
		const chunkedData = data.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / chunkSize);
			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = [];
				chunks.add(chunkIndex);
			}
			resultArray[chunkIndex].push(item);
			return resultArray;
		}, []);
		return { chunkedData, chunks };
	};

	const handleSendToVerificator = async () => {
		setLoading(true);
		updateCursorMode('wait');
		const payload = {
			offer: data.map((item) => ({
				permalink: item.link,
				platformINcountryID: item.platformID,
				offerClassificationID: item.offerClassificationID,
			})),
		};
		const response: any = await sendOffersToVerificator(payload);
		if (response?.success === true) {
			handleSnackbarMessage(t(`${response?.message}`), 'success');
		}
		if (response?.success === false) {
			handleSnackbarMessage(t(`${response?.message}`), 'error');
		}
		updateCursorMode('inherit');
		setLoading(false);
		reloadTable();
	};

	const handleSendData = async () => {
		const { chunkedData } = prepareDataToSubmit();
		if (chunkedData.length > 0) {
			setLoading(true);

			const response: any = await sendNotificationsData(
				chunkedData,
				selectedClient?.id,
				'REMOVIDA'
			);
			if (response?.success === true) {
				const goodItems = [...response.data.goodItems];
				const notGoodItems = [...response.data.notGoodItems];
				setSubmitDataResults({
					good: goodItems,
					notGood: notGoodItems,
				});
				if (notGoodItems.length === 0) {
					handleSnackbarMessage(t(`${response?.message}`), 'success');
				}
				if (goodItems.length > 4 || notGoodItems.length > 0) {
					setOpenSummaryDialog(true);
				}
			} else if (response?.error) {
				handleSnackbarMessage(t(`${response?.message}`), 'success');
			}
			if (response?.success === false) {
				handleSnackbarMessage(t(`${response.message}`), 'error');
			}

			setLoading(false);
			reloadTable();
		}
	};

	const renderAccordionButton = () => (
		<ClickAwayListener onClickAway={() => setOpenButton(false)}>
			<Grid container direction="column" alignItems="center">
				<Grid item xs={12}>
					<ButtonGroup
						disableElevation
						variant="contained"
						color="primary"
						ref={anchorRef}
						aria-label="split button"
					>
						<Button
							size="small"
							variant="contained"
							disabled={selectDisable()}
							onClick={handleToggleButton}
							endIcon={openButton ? <ArrowDropDown /> : <ArrowDropUp />}
							type="submit"
							style={{
								background: applyButtomBackground(),
								color: applyButtomColors(),
							}}
						>
							{data.length < 2
								? t('Notificações.Tabs.Notificadas.Processar item')
								: ` ${t('Notificações.Tabs.Notificadas.Processar')} ${
										data.length
									} ${t('Notificações.Tabs.Notificadas.itens')}`}
						</Button>
					</ButtonGroup>
					<Popper
						open={openButton}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
						placement="top-end"
					>
						{({ TransitionProps }) => (
							<Grow
								{...TransitionProps}
								style={{
									transformOrigin: 'right bottom',
								}}
							>
								<Column>
									<Button
										disableElevation
										variant="contained"
										size="small"
										className={classes.popButton}
										style={{
											width: '100%',
											background: applyButtomBackground(),
											color: applyButtomColors(),
											marginBottom: 1,
											whiteSpace: 'nowrap',
										}}
										onClick={handleOpenDialog}
									>
										{t(
											'Notificações.Tabs.Notificadas.Já removido da plataforma'
										)}
									</Button>
									<Button
										disableElevation
										variant="contained"
										size="small"
										className={classes.popButton}
										style={{
											width: '100%',
											background: applyButtomBackground(),
											color: applyButtomColors(),
											marginBottom: 1,
											whiteSpace: 'nowrap',
										}}
										onClick={(event) => {
											handleSendToVerificator();
											handleCloseButton(event);
										}}
									>
										{loading ? (
											<CircularProgress
												size={15}
												style={{ margin: 4, color: selectedTheme.foreground }}
											/>
										) : (
											t(
												'Notificações.Tabs.Notificadas.Enviar para fila de Verificação'
											)
										)}
									</Button>
								</Column>
							</Grow>
						)}
					</Popper>
				</Grid>
			</Grid>
		</ClickAwayListener>
	);

	const renderWarning = () => (
		<Line
			style={{
				width: '100%',
				background: selectedTheme.background,
				justifyContent: 'center',
				opacity: 0.7,
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : '#eaeaea',
				padding: '10px 15px',
			}}
		>
			<Error style={{ fontSize: 30, color: 'orange' }} />
			<Typography
				variant="caption"
				sx={{
					width: '70%',
					marginLeft: '20px',
					color: selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
				}}
			>
				{t(
					'Notificações.Tabs.Notificadas.O processamento de muitos itens simultaneamente pode levar alguns minutos. Por favor, aguarde.'
				)}
			</Typography>
		</Line>
	);

	const renderDialogContent = () => (
		<Line
			style={{
				justifyContent: 'center',
				padding: '15px 25px',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			}}
		>
			{data.length < 2 ? (
				<Typography
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				>
					{t(
						'Notificações.Tabs.Notificadas.Marcar o anúncio como já removido da plataforma'
					)}
					?
				</Typography>
			) : (
				<Typography
					variant="h6"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				>
					{`${t('Notificações.Tabs.Notificadas.Marcar os')} 
          ${data.length} 
          ${t(
						'Notificações.Tabs.Notificadas.anúncios como já removidos da plataforma'
					)}?`}
				</Typography>
			)}
		</Line>
	);

	const renderFooter = () => (
		<Line style={{ justifyContent: 'space-between' }}>
			<Button
				size="small"
				onClick={handleClose}
				disabled={loading}
				style={{ color: loading ? 'gray' : selectedTheme.error }}
			>
				{t('Notificações.Tabs.Notificadas.Cancelar')}
			</Button>
			{loading ? (
				<LoadingDots height={30} width={70} loop />
			) : (
				<Button
					size="small"
					style={{
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: 'transparent'
						}`,
						color:
							selectedTheme.tone === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.foreground,
						background: selectedTheme.primaryDark,
					}}
					onClick={handleSendData}
				>
					{t('Notificações.Tabs.Notificadas.Marcar')}
				</Button>
			)}
		</Line>
	);

	return (
		<div>
			{renderAccordionButton()}
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				style={{
					cursor: cursorMode,
					display: openSummaryDialog ? 'none' : 'block',
				}}
			>
				<DialogContent
					style={{
						padding: 0,
						background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
					}}
				>
					{renderDialogContent()}
					{data.length > 1 ? renderWarning() : null}
				</DialogContent>
				<DialogActions
					style={{
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#f2f2f2'
						}`,
						padding: 10,
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
					}}
				>
					{renderFooter()}
				</DialogActions>
			</Dialog>
			<SubmitedResumeDialog
				open={openSummaryDialog}
				close={handleCloseDialog}
				data={submitDataResults}
				loading={loading}
			/>
		</div>
	);
};

export default ProcessItemsDialog;
