import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Typography,
} from '@mui/material';
import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { useTranslation } from 'react-i18next';
import { Android, Apple } from '@mui/icons-material/';

interface Props {
	open: boolean;
	handleClose: () => void;
}

const SelectStoreModal: React.FC<Props> = ({ open, handleClose }: Props) => {
	const { selectedTheme, cursorMode } = usePO();
	const { t } = useTranslation();

	const handleCloseModal = () => {
		handleClose();
	};

	const renderDialogContent = () => (
		<DialogContent
			style={{
				padding: 20,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.lightBackground,
				border: `1px solid ${selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea'}`,
			}}
		>
			<Line style={{ gap: 20 }}>
				<IconButton
					style={{
						borderRadius: '4px',
					}}
					onClick={() =>
						window.open(
							'https://play.google.com/store/apps/details?id=br.com.offeru.go',
							'_blank',
							'noopener,noreferrer'
						)
					}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Line style={{ display: 'flex', alignItems: 'flex-start', gap: 5 }}>
						<Android
							style={{
								fontSize: '28px',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.disableBackground,
							}}
						/>
						<Typography
							variant="h6"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							}}
						>
							{'Android'}
						</Typography>
					</Line>
				</IconButton>

				<IconButton
					style={{
						borderRadius: '4px',
					}}
					onClick={() =>
						window.open(
							'https://apps.apple.com/br/app/offer-technologies/id1507955604',
							'_blank',
							'noopener,noreferrer'
						)
					}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<Line style={{ display: 'flex', alignItems: 'flex-start', gap: 5 }}>
						<Apple
							style={{
								fontSize: '28px',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.disableBackground,
							}}
						/>
						<Typography
							variant="h6"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							}}
						>
							{'iOS'}
						</Typography>
					</Line>
				</IconButton>
			</Line>
		</DialogContent>
	);

	const renderFooter = () => (
		<DialogActions
			style={{
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
				border: `1px solid ${selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea'}`,
				padding: 10,
			}}
		>
			<Line style={{ justifyContent: 'right' }}>
				<Button
					size="small"
					onClick={handleCloseModal}
					style={{
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.foreground,
						background: selectedTheme.primaryDark,
						border: `1px solid ${
							selectedTheme.id === 'dark' ? selectedTheme.footerLine : ''
						}`,
					}}
				>
					{t('Fechar')}
				</Button>
			</Line>
		</DialogActions>
	);

	return (
		<>
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				style={{ cursor: cursorMode }}
				PaperProps={{
					style: {
						backgroundColor: 'transparent',
						boxShadow: 'none',
					},
				}}
			>
				{renderDialogContent()}
				{renderFooter()}
			</Dialog>
		</>
	);
};

export default SelectStoreModal;
