import React, { useEffect, useState } from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import '../../../../i18n';
import { usePO } from '../../../../utils/POContext';
import OfferTable from '../../../../components/OfferTable';
import CallDownloadSearch from './CallDownloadSearch';
import BackDropLoading from '../../../../components/BackdropLoading';
import { Line } from '../../../../styles';
import { CloseRounded } from '@mui/icons-material/';
import SearchReportsDialog from './SearchReportsDialog';
import { fetchSearchDetails } from '../../../../services/searches/searchDetails';
import { useSnackbar } from 'notistack';
interface Props {
	rowData: any;
	open: any;
	setOpen: () => void;
}

const SavedSearchesDetails: React.FC<Props> = ({
	rowData,
	open,
	setOpen,
}: Props) => {
	const { selectedTheme, userData } = usePO();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState<any[]>([]);
	const [loadingDownloading, setLoadingDownloading] = useState(false);
	const [currencyINCountry, setCurrencyINCountry] = useState<string>('BRL');

	const colsConfig = [
		{
			columnTitle: t('Buscas.Tabs.Buscas Salvas.Data'),
			fieldName: 'date',
			translateColumnData: false,
			alignTitle: 'left',
			marginTitle: '0 0 0 50px',
			cellWidth: 400,
		},
	];

	const fetchData = async () => {
		setLoading(true);
		setTableData([]);
		const searchDetailsData: any = await fetchSearchDetails(rowData);
		if (searchDetailsData.success) {
			setCurrencyINCountry(
				searchDetailsData.currencyINCountryResponse.currency
			);
			buildTableData(
				searchDetailsData.storageSearchResponse,
				searchDetailsData.clickHouseSearchResponse
			);
		} else {
			enqueueSnackbar(
				t(`Buscas.Tabs.Buscas Salvas.${searchDetailsData.message}`),
				{ variant: 'warning' }
			);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (open && rowData.searchID) {
			fetchData();
		}
		// eslint-disable-next-line
	}, [open, rowData]);

	const buildTableData = async (
		storageDate: string[],
		clickHouseDate: string[]
	) => {
		const storageProcessedDates: string[] = [];
		const clickHouseProcessedDates: string[] = [];
		if (storageDate.length > 0) {
			storageDate.forEach((storage) => {
				const dateString = storage?.split('|');
				const splitedDate = dateString[1]?.split('/');
				if (splitedDate) {
					storageProcessedDates.push(splitedDate[1]);
				}
			});
		}
		if (clickHouseDate.length > 0) {
			clickHouseDate.forEach((clickHouse) => {
				const formatedDate = clickHouse.replace(/_/g, '-');
				clickHouseProcessedDates.push(formatedDate);
			});
		}
		const allDates = [
			...new Set([...storageProcessedDates, ...clickHouseProcessedDates]),
		];
		const availableReportDates = allDates.map((date) => {
			const longDate = new Date(date).toLocaleDateString(userData.i18nID, {
				weekday: 'long',
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			});
			return {
				date: longDate,
				storage: storageProcessedDates.includes(date),
				clickHouse: clickHouseProcessedDates.includes(date),
				storageSearchKey: storageDate.find((item) => item.includes(date)),
				clickHouseSearchKey: date.replace(/-/g, '_'),
			};
		});
		setTableData(availableReportDates);
	};

	const action1Props = {
		searchData: rowData,
		currency: currencyINCountry,
	};

	const action2Props = {
		searchData: rowData,
		setLoadingDownloading: setLoadingDownloading,
	};

	const closeRoundedStyle = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const renderCloseButton = () => (
		<Button
			onClick={setOpen}
			size="small"
			variant="contained"
			style={{
				border: `1px solid ${
					selectedTheme.id === 'dark' ? selectedTheme.footerLine : 'transparent'
				}`,
				color:
					selectedTheme.tone === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.foreground,
				background: selectedTheme.primaryDark,
			}}
		>
			{t('Buscas.Tabs.Buscas Salvas.Fechar')}
		</Button>
	);

	const renderDialogContent = () => (
		<OfferTable
			tableData={tableData}
			colsData={colsConfig}
			tableFooter
			tableFooterButton={renderCloseButton()}
			tableActions
			ActionComponent1={SearchReportsDialog}
			ActionComponent1Props={action1Props}
			ActionComponent2={CallDownloadSearch}
			ActionComponent2Props={action2Props}
			customNoDataMessage={
				loading
					? t('Carregando...')
					: `${t(
							'Buscas.Tabs.Buscas Salvas.Não há detalhes disponíveis para desta busca'
						)}.`
			}
		/>
	);

	const renderHeader = () => (
		<DialogTitle
			style={{
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.tableHead
						: selectedTheme.gradient,
				height: 60,
				color:
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.id === 'main'
							? selectedTheme.primary
							: selectedTheme.foreground,
				display: 'flex',
			}}
			id="max-width-dialog-title"
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Typography
					noWrap
					style={{
						fontSize: 18,
						fontWeight: 'bold',
						maxWidth: '90%',
					}}
				>
					{`${t('Buscas.Tabs.Buscas Salvas.Detalhes da busca')}: ${
						rowData.title
					}`}
				</Typography>
				<IconButton
					onClick={setOpen}
					style={{ marginRight: '-16px' }}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color: closeRoundedStyle(),
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	return (
		<>
			<Dialog
				open={open}
				scroll="body"
				maxWidth="sm"
				fullWidth
				onClose={(reason) => {
					if (reason !== 'backdropClick') {
						setOpen();
					}
				}}
				style={{ cursor: loading ? 'wait' : 'default' }}
			>
				{renderHeader()}
				{renderDialogContent()}
			</Dialog>
			{loadingDownloading ? (
				<BackDropLoading zIndex={3} smallBackdrop width={250} />
			) : null}
		</>
	);
};
export default SavedSearchesDetails;
