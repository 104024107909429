import {
	Checkbox,
	Box,
	Typography,
	Zoom,
	FormGroup,
	FormControlLabel,
} from '@mui/material';
import { Line } from 'src/styles';
import { usePO } from 'src/utils/POContext';
import { OfferToolTip } from 'src/helpers';
import { InfoOutlined } from '@mui/icons-material';
import FormDivider from 'src/components/FormDivider';
import { t } from 'i18next';
import { ClientBranchType } from 'src/interfaces/Clients';
import { useEffect } from 'react';

interface Props {
	formData: ClientBranchType;
	setFormData: (ClientBranchType) => void;
	avaliableReasonsDenouncement: any;
	clientReasonsDenouncement?: any;
	formErrors?: any;
}

const ClientReasonsDenouncements: React.FC<Props> = ({
	formData,
	setFormData,
	avaliableReasonsDenouncement,
	clientReasonsDenouncement,
	formErrors,
}) => {
	const { selectedTheme } = usePO();

	const handleCheckboxChange = (
		reasonId: string,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setFormData((prev) => {
			const newReasons = event.target.checked
				? [...prev.reasons, reasonId]
				: prev.reasons.filter((id) => id !== reasonId);

			return {
				...prev,
				reasons: newReasons,
			};
		});
	};

	useEffect(() => {
		if (clientReasonsDenouncement) {
			setFormData((prev) => ({
				...prev,
				reasons: clientReasonsDenouncement.map((item) => item.id),
			}));
		}
	}, [clientReasonsDenouncement]);

	const allSelected =
		formData.reasons.length > 0 &&
		formData.reasons.length === avaliableReasonsDenouncement.length;

	const handleSelectAll = () => {
		setFormData((prev) => ({
			...prev,
			reasons: allSelected
				? []
				: avaliableReasonsDenouncement.map((item) => item.id),
		}));
	};

	const columns = 2;
	const itemsPerColumn = Math.ceil(
		avaliableReasonsDenouncement.length / columns
	);

	return (
		<>
			<FormDivider
				name={t('Clientes.Selecione os motivos')}
				background={
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground
				}
				margin="15px 0px"
			/>

			<Line>
				{avaliableReasonsDenouncement?.length > 0 && (
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							gap: '20px',
							border: '1px solid',
							borderColor:
								selectedTheme.id === 'dark'
									? selectedTheme.foreground
									: '#e9e9e9',
							borderRadius: 2,
							padding: '10px 15px',
							overflowY: 'auto',
							position: 'relative',
							height: '450px',
						}}
					>
						{[0, 1].map((col) => (
							<Box
								key={col}
								sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
							>
								{col === 0 && (
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											cursor: 'pointer',
										}}
									>
										<FormGroup style={{ width: '100%' }}>
											<FormControlLabel
												control={
													<Checkbox
														style={{
															color:
																selectedTheme.id === 'dark'
																	? selectedTheme.textColorHigh
																	: selectedTheme.primaryDark,
														}}
														checked={allSelected}
														onChange={handleSelectAll}
													/>
												}
												label={
													<Typography
														style={{
															color:
																selectedTheme.id === 'dark' &&
																selectedTheme.textColorHigh,
														}}
														variant="subtitle2"
													>
														{t('Clientes.Selecionar Todos')}
													</Typography>
												}
											/>
										</FormGroup>
									</Box>
								)}

								{col === 1 && (
									<Box sx={{ height: '40px', visibility: 'hidden' }}></Box>
								)}

								{avaliableReasonsDenouncement
									.slice(col * itemsPerColumn, (col + 1) * itemsPerColumn)
									.map((item) => (
										<Box
											key={item.id}
											sx={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'space-between',
												gap: 1,
											}}
										>
											<FormGroup style={{ width: '100%' }}>
												<FormControlLabel
													control={
														<Checkbox
															style={{
																color:
																	selectedTheme.id === 'dark'
																		? selectedTheme.textColorHigh
																		: selectedTheme.primaryDark,
															}}
															checked={formData.reasons.includes(item.id)}
															onChange={(event) =>
																handleCheckboxChange(item.id, event)
															}
														/>
													}
													label={
														<Typography
															style={{
																color:
																	selectedTheme.id === 'dark' &&
																	selectedTheme.textColorHigh,
															}}
															variant="subtitle2"
														>
															{t('ClassificationType.' + item.id)}
														</Typography>
													}
												/>
											</FormGroup>

											<OfferToolTip
												TransitionComponent={Zoom}
												title={t(
													'ClassificationTypeDescription.' + item.description
												)}
												arrow
											>
												<InfoOutlined
													style={{
														fontSize: 16,
														fill:
															selectedTheme.id === 'dark' &&
															selectedTheme.textColorMedium,
													}}
												/>
											</OfferToolTip>
										</Box>
									))}
							</Box>
						))}
					</Box>
				)}
			</Line>

			{formErrors?.reasons && (
				<Typography
					variant="body2"
					sx={{
						marginTop: '8px',
						textAlign: 'center',
						color: selectedTheme.error,
					}}
				>
					{formErrors.reasons}
				</Typography>
			)}
		</>
	);
};

export default ClientReasonsDenouncements;
