import { ErrorResponse } from '../../interfaces';
import { api, isAxiosError } from '../../utils/api';

export const getClassificationsSpreadsheetFile = async (requestBody) => {
	const payload = requestBody.replace(/"size":(?:20|50|100)/g, `"size":10000`);
	try {
		const response = await api.post('/reports/clients/export/offer', payload, {
			timeout: 5 * 60 * 500, // 5 minutes
		});
		const downloadUrl = response.data;
		const link = document.createElement('a');
		link.href = downloadUrl;
		link.click();
		return { success: true, message: 'Arquivo baixado com sucesso' };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getOffersByElasticSearch = async (requestBody) => {
	try {
		const response = await api.post(
			'/offer/elasticsearch/_msearch',
			requestBody
		);
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getOfferClassificationByID = async (clientID, offerID) => {
	try {
		const response = await api.get(
			`/offerClassification/clients/${clientID}/offers/${offerID}`
		);
		if (response.data === null) {
			return {
				success: false,
				status: response.status,
				data: 'notClassified',
				message: 'Classificação não encontrada',
			};
		}
		return { success: true, data: response.data };
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const submitTags = async (payload: any, selectedClientID: string) => {
	try {
		const tagsResponse = await api.post(
			`/tagINoffer/elasticsearch?clientID=${selectedClientID}`,
			payload,
			{
				timeout: 300 * 1000,
			}
		);
		return {
			success: true,
			status: tagsResponse.status,
			data: tagsResponse.data,
			message: tagsResponse.data.detail || 'Etiquetas atualizadas com sucesso',
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const submitClassification = async (payload: any) => {
	try {
		const response = await api.post(`/offerClassification`, payload, {
			timeout: 600000,
		});
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail || 'Classificação atualizada com sucesso',
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const removeClassification = async (clientID, offerID) => {
	try {
		const response = await api.delete(
			`/offerClassification/${clientID}/clients/${offerID}`
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail || 'Classificação removida com sucesso',
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};

export const getReasonsDenouncementClient = async (clientID) => {
	try {
		const response = await api.get(
			`/clientINclassificationType/client/${clientID}`
		);
		return {
			success: true,
			status: response.status,
			data: response.data,
			message: response.data.detail,
		};
	} catch (error) {
		if (isAxiosError(error)) {
			const errorResponse = error.response as ErrorResponse | undefined;
			if (errorResponse && errorResponse.data) {
				return {
					success: false,
					status: errorResponse.status,
					message: errorResponse.data.detail,
				};
			} else {
				return {
					success: false,
					status: 500,
					message:
						'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
				};
			}
		}
	}
};
