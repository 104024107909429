import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Zoom,
	Box,
	CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientBranchType } from 'src/interfaces/Clients';
import { CloseRounded, NoteAlt } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import {
	createNewClientReasons,
	deleteClientReasons,
	getReasonsDenouncement,
	getReasonsDenouncementClient,
} from 'src/services/client';
import { OfferToolTip } from 'src/helpers';

import ClientReasonsDenouncements from '../../ClientNewForm/ClientReasonsDenouncements';
import { isAxiosError } from 'axios';
import { ErrorResponse } from 'src/interfaces';
import LoadingDots from 'src/components/LoadingDots';

interface Props {
	rowData: ClientBranchType;
	refresh: () => void;
}

const ClientEditReasons: React.FC<Props> = ({ rowData, refresh }: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const [loading, setLoading] = useState<boolean>(false);

	const [avaliableReasonsDenouncement, setAvaliableReasonsDenouncement] =
		useState([]);
	const [clientReasonsDenouncement, setClientReasonsDenouncement] = useState(
		[]
	);

	const [formData, setFormData] = useState<ClientBranchType>({
		id: '',
		name: '',
		parent: '',
		clientRegNumber: '',
		clientID: rowData.id,
		countryID: '',
		country: '',
		stateName: '',
		cityName: '',
		zipCode: '',
		streetAddress: '',
		numberAddress: '',
		complement: '',
		contactName: '',
		BIID: '',
		email1: '',
		email2: '',
		phone1: '',
		phone2: '',
		description: '',
		ISactive: false,
		reasons: [],
	});

	const fetchAvailableReasonsDenouncement = async () => {
		try {
			setLoading(true);
			const response: any = await getReasonsDenouncement();
			setAvaliableReasonsDenouncement(response.data);
			await fetchReasonsDenouncementINClient();
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					return {
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
					};
				} else {
					return {
						success: false,
						status: 500,
						message:
							'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
					};
				}
			}
		}
	};

	const fetchReasonsDenouncementINClient = async () => {
		try {
			setLoading(true);
			const response: any = await getReasonsDenouncementClient(rowData.id);
			setClientReasonsDenouncement(response.data);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					return {
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
					};
				} else {
					return {
						success: false,
						status: 500,
						message:
							'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
					};
				}
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		if (open) {
			setLoading(true);
			fetchAvailableReasonsDenouncement();
		}
	}, [open]);

	useEffect(() => {
		if (
			clientReasonsDenouncement.length > 0 &&
			avaliableReasonsDenouncement.length > 0
		) {
			const clientReasonIDs = clientReasonsDenouncement.map(
				(reason: any) => reason.id
			);

			const preSelectedReasons = avaliableReasonsDenouncement.filter(
				(reason: any) => clientReasonIDs.includes(reason.id)
			);

			setFormData((prev) => ({
				...prev,
				reasons: preSelectedReasons.map((reason: any) => reason.id),
			}));
		}
	}, [clientReasonsDenouncement, avaliableReasonsDenouncement]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		handleResetForm();
	};

	const handleResetForm = () => {
		setFormData({
			...formData,
			reasons: [],
		});
	};

	const handleSave = async () => {
		try {
			setLoading(true);

			const reasonsToAdd = formData.reasons.filter(
				(id: string) =>
					!clientReasonsDenouncement
						.map((reason: any) => reason.id)
						.includes(id)
			);

			const reasonsToDelete = clientReasonsDenouncement.filter(
				(reason) => !formData.reasons.includes((reason as { id: string }).id)
			);

			if (reasonsToAdd.length === 0 && reasonsToDelete.length === 0) {
				handleClose();
				return;
			}

			if (reasonsToAdd.length > 0) {
				const addPayload = {
					clientID: rowData.id,
					classificationTypeID: reasonsToAdd,
				};
				const addResponse: any = await createNewClientReasons(addPayload);

				if (!addResponse.success) {
					enqueueSnackbar(t('Clientes.Tipo de classificação não encontrado.'), {
						variant: 'error',
					});
				} else {
					enqueueSnackbar(
						t(
							'Clientes.Relação entre cliente e tipo de classificação adicionada com sucesso.'
						),
						{ variant: 'success' }
					);
				}
			}

			if (reasonsToDelete.length > 0) {
				let allDeletionsSuccessful = true;
				for (const reason of reasonsToDelete) {
					const reasonId = (reason as { id: string }).id;

					const deleteResponse: any = await deleteClientReasons(
						`${rowData.id}|${reasonId}`
					);

					if (!deleteResponse.success) {
						allDeletionsSuccessful = false;
						enqueueSnackbar(
							t(
								'Clientes.Relação entre o tipo de classificação e cliente não encontrada.'
							),
							{ variant: 'error' }
						);
					}
				}

				if (allDeletionsSuccessful) {
					enqueueSnackbar(
						t(
							'Clientes.Relação entre cliente e tipo de classificação removida com sucesso.'
						),
						{ variant: 'warning' }
					);
				}

				setFormData((prev) => ({
					...prev,
					reasons: prev.reasons.filter(
						(id) =>
							!reasonsToDelete
								.map((reason) => (reason as { id: string }).id)
								.includes(id)
					),
				}));
			}

			handleClose();
			refresh();
		} catch (error) {
			console.log('Error', { variant: 'error' });
		} finally {
			setLoading(false);
		}
	};

	const typographyStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const handleFormTitle = () => {
		return (
			<Typography
				noWrap
				style={{
					color: typographyStyleHeader(),
					fontSize: 20,
					fontWeight: 'bold',
					maxWidth: '90%',
				}}
			>
				{`${t('Clientes.Editar Motivos de Denúncia')}: ${rowData.name}`}
			</Typography>
		);
	};

	return (
		<div>
			<IconButton
				onClick={handleOpen}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<OfferToolTip
					title={`${t('Clientes.Editar Motivos de Denúncia')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<NoteAlt
						style={{
							fontSize: 20,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</OfferToolTip>
			</IconButton>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
				<DialogTitle
					style={{
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						color: '#FFFFFF',
						display: 'flex',
					}}
					id="draggable-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
						}}
					>
						{handleFormTitle()}

						<IconButton
							onClick={handleClose}
							style={{ marginRight: '-16px' }}
							sx={{
								'&:hover': {
									backgroundColor: selectedTheme.hoverBackground,
								},
							}}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'main'
											? selectedTheme.primary
											: 'white',
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
				<DialogContent
					style={{
						padding: 22,
						width: '100%',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					}}
				>
					{loading ? (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="200px"
						>
							<CircularProgress
								size={30}
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.primaryDark,
								}}
							/>
						</Box>
					) : (
						<ClientReasonsDenouncements
							formData={formData}
							setFormData={setFormData}
							avaliableReasonsDenouncement={avaliableReasonsDenouncement}
							clientReasonsDenouncement={clientReasonsDenouncement}
						/>
					)}
				</DialogContent>

				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#eaeaea',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
					}}
				>
					<Line
						style={{
							justifyContent: 'space-between',
							padding: '5px 10px',
						}}
					>
						<Button
							onClick={handleClose}
							style={{ color: selectedTheme.error }}
						>
							{t('Cancelar')}
						</Button>
						{loading ? (
							<LoadingDots width={100} height={30} loop />
						) : (
							<Button
								onClick={handleSave}
								variant="contained"
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.foreground,
									background: selectedTheme.primaryDark,
									border: handleBorderButton(),
								}}
							>
								{t('Clientes.Salvar')}
							</Button>
						)}
					</Line>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ClientEditReasons;
