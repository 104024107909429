import React, { useEffect, useState } from 'react';
import {
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Typography,
} from '@mui/material';
import { Link, CloseRounded } from '@mui/icons-material/';
import { makeStyles, withStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Footer, Line } from '../../../../styles';
import { api, isAxiosError, setupRequestToken } from '../../../../utils/api';
import { useAuth0 } from '../../../../react-auth0-spa';
import TableSearch from '../../../../components/TableSearch';
import '../../../../i18n';
import { usePO } from '../../../../utils/POContext';
import { ErrorResponse } from '../../../../interfaces';
import { enqueueSnackbar } from 'notistack';

/**
 * Table header construction
 * Columns, sorting and styling functions
 */

interface Data {
	name: string;
	id: number;
}

function createData(name: string, id: number): Data {
	return { name, id };
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: any;
	label: string;
	numeric: boolean;
}

/**
 * Table sorting and selecting functions
 */

interface EnhancedTableProps {
	classes: any;
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<any>, property: any) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const { t } = useTranslation();
	const { selectedTheme } = usePO();
	const { classes, order, orderBy, onRequestSort } = props;

	/**
	 * Controls tabele header content parameters and styling
	 */

	const headCells: HeadCell[] = [
		{
			id: 'name',
			numeric: false,
			disablePadding: false,
			label: t('Usuários.Nome'),
		},
		{
			id: 'actions',
			numeric: false,
			disablePadding: false,
			label: t('Usuários.Ações'),
		},
	];

	const createSortHandler =
		(property: any) => (event: React.MouseEvent<any>) => {
			onRequestSort(event, property);
		};

	/**
	 * Table Header output
	 */

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.label === t('Usuários.Nome') ? 'left' : 'right'}
						padding="normal"
						sortDirection={orderBy === headCell.id ? order : false}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '#000000de',
							borderBottom:
								selectedTheme.id === 'dark'
									? `1px solid ${selectedTheme.footerLine}`
									: '',

							background:
								selectedTheme.id === 'dark' && selectedTheme.overlay4dp,
							paddingLeft: 40,
							paddingRight: 40,
						}}
					>
						<TableSortLabel
							disabled={headCell.id === 'actions' ? true : false}
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
							sx={{
								'&.Mui-active': {
									'.MuiTableSortLabel-icon': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
									},
								},
								'.MuiTableSortLabel-icon': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorDisable,
								},
							}}
						>
							<Typography
								variant="subtitle2"
								style={{
									fontWeight: 'bold',
									alignItems: 'end',
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorMedium
											: '#000000de',
								}}
							>
								{headCell.label}
							</Typography>
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

/**
 * Table Columns content parameters and styling
 */

interface Props {
	rowData: any;
	refresh?: any;
}

const UserBindModal: React.FC<Props> = ({ rowData }: Props) => {
	const { token } = useAuth0();
	const { selectedTheme, updateUserDataClient } = usePO();
	const { t } = useTranslation();
	setupRequestToken(api, token);

	/**
	 * State Variables
	 */
	const useStyles = makeStyles(() => ({
		tablePaper: {
			width: '100%',
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
		},
		header: {
			background: 'warning',
		},
		container: {
			maxHeight: '100%', // STICKY HEADER
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		selectDropdown: {
			color:
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.disableBackground,
			backgroundColor:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay4dp
					: selectedTheme.disableBackground,
		},
		rootPagination: {
			'&:focus': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
			},
		},
		menuItem: {
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.disableBackground,
			},
		},
		paginationSelectIcon: {
			color:
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.disableBackground,
		},
	}));
	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(
		window.innerHeight > 750 ? 10 : Math.round(window.innerHeight / 100) + 1
	);

	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState<any>('name');
	const [clientsData, setClientsData] = useState<any>([]);
	const [selectedRows, setselectedRows] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [loadingPage, setLoadingPage] = useState(false);
	const [open, setOpen] = useState(false);

	// TableSearch component
	const [searchText, setSearchText] = useState('');

	const reFectchData = async () => {
		setLoading(true);
		try {
			const userINclientResponse = await api.get(
				`/userINclient/users/${rowData.id}`
			);
			setselectedRows(userINclientResponse.data);
			setLoading(false);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse?.data?.detail, { variant: 'error' });
				}
			}
		} finally {
			setLoading(false);
		}
	};

	/**
	 * Fetch table content on loading
	 */

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			setLoadingPage(true);
			setselectedRows([]);
			try {
				const clientDataResponse = await api.get('/clients');
				setClientsData(clientDataResponse.data);
				if (rowData.id) {
					const userINclientResponse = await api.get(
						`/userINclient/users/${rowData.id}`
					);
					setselectedRows(userINclientResponse.data);
				}
			} catch (error) {
				if (isAxiosError(error)) {
					const errorResponse = error.response as ErrorResponse | undefined;
					if (errorResponse && errorResponse.data) {
						enqueueSnackbar(errorResponse?.data?.detail, { variant: 'error' });
					}
				}
			} finally {
				setLoading(false);
				setLoadingPage(false);
			}
		};
		if (open) {
			fetchData();
		}
		// eslint-disable-next-line
	}, [rowData.id, open]);

	const handleRequestSort = (event: React.MouseEvent<any>, property: any) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setSearchText('');
		updateUserDataClient();
	};

	const handleSelectAllClick = () => {
		return null;
	};

	const handleBindUser = async (data: any) => {
		if (!loading) {
			setLoading(true);
			const payload = {
				userID: rowData.id,
				clientID: data.id,
				ISactive: true,
			};
			try {
				enqueueSnackbar(
					t('Usuários.Relação entre usuário e cliente adicionada com sucesso.'),
					{ variant: 'success' }
				);
				await api.post(`/userINclient`, payload);
				setLoading(false);
				reFectchData();
			} catch (error) {
				if (isAxiosError(error)) {
					const errorResponse = error.response as ErrorResponse | undefined;
					if (errorResponse && errorResponse.data) {
						enqueueSnackbar(errorResponse?.data?.detail, { variant: 'error' });
					}
				}
			} finally {
				setLoading(false);
			}
		}
	};

	const handleUnbindUser = async (data: any) => {
		if (!loading) {
			setLoading(true);
			try {
				enqueueSnackbar(
					t('Usuários.Relação entre usuário e cliente removida com sucesso.'),
					{ variant: 'success' }
				);
				await api.delete(`/userINclient/${rowData.id}|${data.id}`);
				setLoading(false);
				reFectchData();
			} catch (error) {
				if (isAxiosError(error)) {
					const errorResponse = error.response as ErrorResponse | undefined;
					if (errorResponse && errorResponse.data) {
						enqueueSnackbar(errorResponse?.data?.detail, { variant: 'error' });
					}
				}
			} finally {
				setLoading(false);
			}
		}

		const userINclientResponse = await api.get(
			`/userINclient/users/${rowData.id}`
		);
		if (userINclientResponse.data.length === 0) {
			const userDataPreference = await api.get(`/users/${rowData.id}`);
			userDataPreference.data.preferences.selectedClient = null;
			const payload = {
				preferences: {
					selectedClient: userDataPreference.data.preferences.selectedClient,
					selectedTheme: {
						id: userDataPreference.data.preferences.selectedTheme.id,
					},
				},
			};
			await api.patch(`/users/${rowData.id}`, payload);
		}
	};

	const handleSelectClick = (data: any) => {
		if (!loading) {
			const bindedID = data.id;
			const selectedIndex = selectedRows.findIndex((e) => e.id === bindedID);

			if (selectedIndex === -1) {
				selectedRows.push(data);
				handleBindUser(data);
			} else if (selectedIndex !== -1) {
				handleUnbindUser(data);
			}
		}
	};

	const isSelected = (data: string) =>
		selectedRows.findIndex((e) => e.id === data) !== -1;

	/**
	 * Pre-format data from backend to fit the table specifications
	 */

	const preRows = clientsData.map((client: any) => [
		createData(client.name, client.id),
	]);

	/**
	 * Adds search logic to table data
	 */

	const tableData = preRows
		.map((el) => el[0])
		.filter((data) => {
			if (searchText == null) return data;
			if (
				data.name?.toLowerCase().includes(searchText.toLowerCase()) ||
				data.email?.toLowerCase().includes(searchText.toLowerCase())
			) {
				return data;
			}
			return null;
		});

	const PaginationTheme = withStyles({
		selectIcon: {
			color:
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.disableBackground,
		},
		menuItem: {
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.disableBackground,
			},
		},
		select: {
			'&:focus': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
			},
		},
		actions: {
			'& .Mui-disabled': {
				color: '',
			},
		},
	})(TablePagination);

	const renderFooter = () => {
		return (
			<Footer
				style={{
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#f2f2f2',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
				}}
			>
				<Divider sx={{ opacity: 0.6 }} />
				<Line style={{ justifyContent: 'space-between' }}>
					<div
						style={{ display: 'flex', justifyContent: 'start', width: '50%' }}
					>
						<TableSearch setSearchText={setSearchText} />
					</div>
					{tableData.length > 10 ? (
						<PaginationTheme
							rowsPerPageOptions={[10, 20, 50]}
							count={tableData.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							slotProps={{
								select: {
									MenuProps: { classes: { paper: classes.selectDropdown } },
								},
							}}
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.disableBackground,
								borderBottom: 'none',
							}}
						/>
					) : null}
				</Line>
			</Footer>
		);
	};

	/**
	 * Return main table
	 */

	return (
		<div>
			<IconButton data-testid="linkUserModal" onClick={handleOpen}>
				<Link
					style={{
						fontSize: 23,
						transform: 'rotate(45deg)',
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
					}}
				/>
			</IconButton>
			<Dialog
				aria-labelledby="simple-dialog-title"
				open={open}
				scroll="paper"
				maxWidth="md"
				fullWidth
				disableEscapeKeyDown
				style={{ cursor: loading ? 'wait' : 'inherit' }}
			>
				<DialogTitle
					style={{
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						display: 'flex',
					}}
					id="max-width-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
						}}
					>
						<Typography
							data-testid="headerBind"
							noWrap
							style={{
								color:
									selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
								fontSize: 20,
								fontWeight: 'bold',
								maxWidth: '90%',
							}}
						>{`${t('Usuários.Vincular usuário')}: ${rowData.name}`}</Typography>
						<IconButton
							data-testid="closeModalBind"
							onClick={handleClose}
							style={{ marginRight: '-16px' }}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'main'
											? selectedTheme.primary
											: 'white',
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
				<DialogContent style={{ padding: 0 }}>
					<Paper className={classes.tablePaper}>
						<Divider />
						<Divider />
						<TableContainer className={classes.container}>
							<Table stickyHeader size="small" aria-label="sticky table">
								<EnhancedTableHead
									classes={classes}
									numSelected={clientsData.length}
									order={order}
									orderBy={orderBy}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={tableData.length}
								/>
								<TableBody>
									{stableSort(tableData, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											const labelId = `enhanced-table-checkbox-${index}`;
											const isItemSelected = isSelected(row.id);

											return (
												<TableRow
													hover
													role="checkbox"
													tabIndex={-1}
													key={row.name}
													style={{
														background:
															selectedTheme.id === 'dark'
																? selectedTheme.overlay6dp
																: selectedTheme.foreground,
													}}
												>
													<TableCell
														component="th"
														id={labelId}
														scope="row"
														align="justify"
														data-testid="clientName"
														style={{
															minWidth: 300,
															padding: '3px 20px',
															color:
																selectedTheme.id === 'dark'
																	? selectedTheme.textColorHigh
																	: '',
															borderBottom:
																selectedTheme.id === 'dark'
																	? `1px solid ${selectedTheme.footerLine}`
																	: '',
														}}
													>
														<Typography style={{ marginLeft: 20 }}>
															{row.name}
														</Typography>
													</TableCell>
													<TableCell
														align="right"
														style={{
															minWidth: 30,
															height: 30,
															padding: '3px 40px',
															color:
																selectedTheme.id === 'dark'
																	? selectedTheme.textColorHigh
																	: '',
															borderBottom:
																selectedTheme.id === 'dark'
																	? `1px solid ${selectedTheme.footerLine}`
																	: '',
														}}
													>
														<Checkbox
															data-testid="linkCheckbox"
															color="primary"
															onClick={() => handleSelectClick(row)}
															checked={isItemSelected || loadingPage}
															indeterminate={
																(loading && isSelected(row.id)) || loadingPage
															}
															inputProps={{
																readOnly: loading,
																'aria-labelledby': labelId,
															}}
															style={{
																cursor: loading ? 'wait' : 'inherit',
																color:
																	selectedTheme.id === 'dark'
																		? selectedTheme.textColorHigh
																		: selectedTheme.primary,
															}}
														/>
													</TableCell>
												</TableRow>
											);
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</DialogContent>
				{renderFooter()}
			</Dialog>
		</div>
	);
};

export default UserBindModal;
