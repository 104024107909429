import React, { useState } from 'react';
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	Zoom,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { api, setupRequestToken } from '../../../../utils/api';
import Loading from '../../../../components/LoadingDots';
import { useAuth0 } from '../../../../react-auth0-spa';
import { Line } from '../../../../styles';
import { OfferSwitch, OfferToolTip } from '../../../../helpers';
import '../../../../i18n';
import { usePO } from '../../../../utils/POContext';
import { updateClientUnit } from '../../../../services/client';
import { useSnackbar } from 'notistack';
import { apiResponse } from '../../../../interfaces';
import { ClientBranchType } from '../../../../interfaces/Clients';
interface props {
	rowData: ClientBranchType;
	refresh: () => void;
}

const ClientDeactivateDialog: React.FC<props> = ({
	rowData,
	refresh,
}: props) => {
	const { token } = useAuth0();
	setupRequestToken(api, token);
	const { t } = useTranslation();
	const { selectedTheme } = usePO();
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const [switchState, setSwitchState] = useState(rowData.ISactive);
	const [clientSwitchState, setClientSwitchState] = useState(switchState);

	const switchOfferStyles = (switchState) => {
		if (switchState) {
			return selectedTheme.id === 'dark'
				? selectedTheme.textColorHigh
				: selectedTheme.primary;
		} else {
			return selectedTheme.id === 'dark'
				? selectedTheme.primaryDark
				: selectedTheme.disabled;
		}
	};

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const handlePanelsSwitchChange = (event) => {
		setClientSwitchState(event.target.checked);
		setOpen(true);
	};

	const handleClose = () => {
		setClientSwitchState(switchState);
		setOpen(false);
	};

	const handleClientUnit = async () => {
		const payload = {
			id: rowData.id,
			clientID: rowData.clientID,
			countryID: rowData.countryID,
			country: rowData.countryID,
			clientRegNumber: rowData.clientRegNumber,
			zipCode: rowData.zipCode,
			streetAddress: rowData.streetAddress,
			numberAddress: rowData.numberAddress,
			cityName: rowData.cityName,
			stateName: rowData.stateName,
			complement: rowData.complement,
			contactName: rowData.contactName,
			phone1: rowData.phone1,
			phone2: rowData.phone2,
			email1: rowData.email1,
			BIID: rowData.BIID,
			email2: rowData.email2,
			ISactive: !rowData.ISactive,
		};
		setLoading(true);
		const response: apiResponse | undefined = await updateClientUnit(
			rowData.id,
			payload
		);
		if (response && response.status) {
			enqueueSnackbar(
				t('Clientes.Relação entre cliente e país adicionada com sucesso.'),
				{
					variant: 'success',
				}
			);
		} else {
			enqueueSnackbar(
				t('Clientes.Relação entre cliente e país não encontrada.'),
				{
					variant: 'warning',
				}
			);
		}
		handleClose();
		refresh();
		setSwitchState(clientSwitchState);
	};

	const renderFooter = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
				background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
					padding: '5px 10px',
				}}
			>
				<Button
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Clientes.Cancelar')}
				</Button>
				{loading ? (
					<Loading width={100} height={30} loop />
				) : (
					<Button
						onClick={handleClientUnit}
						style={{
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.foreground,
							background: selectedTheme.primaryDark,
							border: handleBorderButton(),
						}}
					>
						{clientSwitchState ? t('Clientes.Ativar') : t('Clientes.Desativar')}
					</Button>
				)}
			</Line>
		</DialogActions>
	);

	return (
		<div>
			<div style={{ marginLeft: 10 }}>
				<OfferToolTip
					title={
						switchState
							? `${t('Clientes.Desativar a unidade')}`
							: `${t('Clientes.Ativar a unidade')}`
					}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<OfferSwitch
						disabled={rowData.error}
						size="small"
						checked={switchState}
						onChange={handlePanelsSwitchChange}
						style={{
							color: switchOfferStyles(clientSwitchState),
						}}
					/>
				</OfferToolTip>
			</div>
			<Dialog
				open={open}
				disableEscapeKeyDown
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
			>
				<DialogContent
					style={{
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					}}
				>
					<Line
						style={{
							justifyContent: 'space-between',
							padding: '2px 10px 10px 10px',
							color:
								selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '',
						}}
					>
						<Typography data-testid="textDisable" variant="h6">
							{`${
								switchState
									? t('Clientes.Desativar a unidade')
									: t('Clientes.Ativar a unidade')
							}:`}
						</Typography>
						<Typography variant="h6" style={{ marginLeft: 5 }}>
							{`${rowData?.country}`}
						</Typography>
						<Typography variant="h6">?</Typography>
					</Line>
				</DialogContent>
				{renderFooter()}
			</Dialog>
		</div>
	);
};

export default ClientDeactivateDialog;
