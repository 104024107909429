import React, { useState } from 'react';
import {
	Button,
	Dialog,
	Checkbox,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
	FormControlLabel,
} from '@mui/material';
import { usePO } from '../../utils/POContext';
import { CloseRounded } from '@mui/icons-material/';
import { Column, Line } from '../../styles';
import { isAxiosError } from '../../utils/api';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { ErrorResponse, apiResponse } from '../../interfaces';
import { setTermsAccept } from '../../services/terms/termsAccept';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleChangeAcceptedTerms?: (state: boolean) => void | undefined;
	isoledComponent?: boolean;
}

const TermsOfUseModal: React.FC<Props> = ({
	open,
	handleClose,
	handleChangeAcceptedTerms,
	isoledComponent,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [checkBoxData, setCheckBoxData] = useState<boolean>(false);

	const handleThemeTextHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primary;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckBoxData(event.target.checked);
	};

	const handleDisableInformativeSnackbar = async () => {
		if (checkBoxData) {
			let response: apiResponse | undefined;
			try {
				response = await setTermsAccept();
				if (response && handleChangeAcceptedTerms) {
					enqueueSnackbar(response.data.detail, {
						variant: 'success',
					});
					handleChangeAcceptedTerms(checkBoxData);
				}
				handleClose();
			} catch (error) {
				if (isAxiosError(error)) {
					const errorResponse = error.response as ErrorResponse | undefined;
					if (errorResponse && errorResponse.data) {
						console.log(errorResponse?.data?.detail);
					}
				}
			}
		} else {
			enqueueSnackbar(
				`${t('É necessário que os termos de uso sejam aceitos')}.`,
				{
					variant: 'warning',
				}
			);
		}
	};

	const RenderDialogHeader = () => (
		<DialogTitle
			style={{
				padding: '10px 20px',
				borderBottom: `2px solid ${
					selectedTheme.id === 'dark'
						? selectedTheme.footerLine
						: selectedTheme.disabled
				}`,
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.tableHead
						: selectedTheme.gradient,
				display: 'flex',
			}}
		>
			<Line
				style={{
					justifyContent: 'space-between',
				}}
			>
				<Typography
					variant="h6"
					style={{
						fontWeight: 'bold',
						color: handleThemeTextHeader(),
					}}
				>
					Termos de Uso e Politica de Privacidade
				</Typography>
				<IconButton
					data-testid="closeTermsButton"
					onClick={handleClose}
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
				>
					<CloseRounded
						style={{
							color:
								selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
						}}
					/>
				</IconButton>
			</Line>
		</DialogTitle>
	);

	const RenderCardTerms = () => (
		<Column
			style={{
				rowGap: 10,
			}}
		>
			<Typography
				align="left"
				variant="subtitle1"
				style={{
					fontWeight: 'bold',
					width: '100%',
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					textIndent: ' 1.5em',
					marginBottom: 30,
				}}
			>
				Por favor, leia os seguintes Termos de Uso e a Politica de Privacidade.
				Você deve ler e aceitar os termos do contrato antes de prosseguir.
			</Typography>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
				}}
			>
				<Typography
					align="center"
					variant="h5"
					style={{
						fontWeight: 'bold',
						width: '100%',
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					TERMOS DE USO: Aplicativo e Extensão
				</Typography>
				<Typography
					align="center"
					style={{
						fontWeight: 'bold',
						width: '100%',
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					Versão atualizada em 03/06/2024.
				</Typography>
			</div>
			<Typography
				align="justify"
				style={{
					width: '100%',
					margin: '30px 0px 10px',
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
				}}
			>
				Prezado(a) Usuário(a), para que você possa utilizar a extensão é
				necessário ler atentamente os termos de uso a seguir:
			</Typography>
			<Typography
				align="justify"
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					textIndent: ' 1.5em',
				}}
			>
				A Offer Technologies é criadora e titular das ferramentas OFFERU, OFFERZ
				e Offer Platform sediada em Florianópolis, Santa Catarina, Brasil, na
				Rua Santa Luzia, 100, Sala 801, CEP 88.036-540. A Offer Platform
				possibilita que o usuário possa de forma rápida e segura manifestar sua
				opinião sobre uma determinada oferta ou conteúdo, caso suspeite de que
				os mesmos estejam divulgando a venda de produto(s) que, por sua vez,
				infrinja(m) direito(s) de propriedade intelectual alheios, tais como,
				produtos piratas ou falsificados. As opiniões do usuário serão chamadas
				de “denúncia”.
			</Typography>
			<Typography
				align="justify"
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					textIndent: ' 1.5em',
				}}
			>
				A(s) denúncia(s) do(s) usuário(s) será(ão) objeto de análise por
				profissionais especializados, que confirmarão ou não a mesma. Em caso
				positivo, o titular do direito de propriedade intelectual infringido
				adotará as providências cabíveis para denúncia, exclusão da referida
				oferta ou conteúdo e você poderá acompanhar o status da providência.
				Para que você possa utilizar a ferramenta é necessário ler atentamente
				os termos de uso a seguir, bem como as permissões e, somente utilizar o
				sistema se você estiver de acordo com as mesmas. O usuário poderá
				denunciar URLs veiculados ou não por meio de redes sociais, e-commerces
				e sites através da ferramenta de denúncia digital.
			</Typography>
			<Typography
				align="justify"
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					textIndent: ' 1.5em',
				}}
			>
				O usuário poderá também denunciar estabelecimentos comerciais, por meio
				da ferramenta de denúncia física, onde indicará a localização do
				estabelecimento através do preenchimento de um formulário ou concessão
				da permissão de acesso a sua localização. A Offer Tecnhologies, por sua
				vez, compromete-se a manter estrito sigilo em relação à informação que o
				usuário lhe compartilhar, bem como, a garantir total privacidade em
				relação a outras informações compartilhadas pelo usuário, bem como,
				comprometendo-se a utilizar as informações da URL denunciada
				exclusivamente para o fim de monitoramento de legalidade do referido
				conteúdo.
			</Typography>
			<Typography
				align="justify"
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					textIndent: ' 1.5em',
				}}
			>
				A Offer Technologies ficará isenta de responsabilidade perante o usuário
				caso tenha de fornecer informações por força de ordem judicial ou
				imposição legal. Após a obtenção da URL denunciada, a Offer Technologies
				Ltda passará a monitorar a mesma a partir de servidor próprio. O usuário
				que atender as condições comerciais estabelecidas pela Offer
				Technologies Ltda terá uma licença temporária de uso do software
				&quot;Plataforma Offer&quot;, na modalidade personal computer. O usuário
				compromete-se em manter sigilo de sua senha de acesso, não podendo
				compartilhá-la com outro(s), comprometendo-se também a utilizar a
				&quot;Plataforma Offer&quot; apenas em um computador de sua propriedade.
				Os direitos de propriedade intelectual referentes ao software, layouts,
				marcas, conteúdos, que caracterizam a Plataforma Offer pertencem
				exclusivamente à OFFER TECHNOLOGIES LTDA.
			</Typography>
			<Typography
				align="justify"
				style={{
					color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					textIndent: ' 1.5em',
				}}
			>
				A Offer Technologies poderá a qualquer momento, por sua mera
				liberalidade, realizar atualizações e modificações no sistema Plataforma
				Offer, independentemente de qualquer autorização prévia do usuário. A
				Offer Technologies poderá a seu critério descontinuar os serviços
				Plataforma Offer, bastando avisar o usuário, através do email de seu
				cadastro, com 10 dias de antecedência. O Usuário que infringir os termos
				de uso, ou que proceder de forma ilegal, terá seu acesso imediatamente
				bloqueado pela Offer Technologies, sem prejuízo de sofrer outras sanções
				de cunho civil ou penal.
			</Typography>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: 10,
					marginTop: 30,
					width: '100%',
				}}
			>
				<Typography
					align="left"
					style={{
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					Em caso de dúvidas ou sugestões, contate o email a seguir:
				</Typography>
				<Typography
					align="right"
					variant="h6"
					style={{
						fontWeight: 'bold',
						color:
							selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
					}}
				>
					contato@offertech.com.br
				</Typography>
			</div>
		</Column>
	);

	const RenderDialogContent = () => (
		<DialogContent
			style={{
				padding: '40px 60px 90px 68px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme.background
						: selectedTheme.foreground,
				gap: '30px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<RenderCardTerms />
		</DialogContent>
	);

	const RenderDialogActions = () => (
		<DialogActions
			style={{
				borderTop: 'solid',
				borderTopWidth: 1,
				borderTopColor:
					selectedTheme.id === 'dark'
						? selectedTheme.footerLine
						: selectedTheme.disabled,
				padding: '10px 20px',
				background:
					selectedTheme.id === 'dark'
						? selectedTheme?.overlay3dp
						: selectedTheme?.foreground,
			}}
		>
			<Line style={{ justifyContent: 'space-between' }}>
				<FormControlLabel
					control={
						<Checkbox
							data-testid="termsCheckBox"
							checked={checkBoxData}
							onChange={handleChangeCheckBox}
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					}
					label={
						<Typography
							align="left"
							variant="body1"
							style={{
								width: '100%',
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							}}
						>
							Declaro que li e concordo integralmente com Termos de Uso e a
							politica de privacidade.
						</Typography>
					}
				/>
				<Button
					variant="contained"
					data-testid="confirmButtonxTermsModal"
					style={{
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme.footerLine
								: 'transparent'
						}`,
						color:
							selectedTheme.id === 'dark'
								? selectedTheme.textColorHigh
								: selectedTheme.foreground,
						background: selectedTheme.primaryDark,
					}}
					onClick={handleDisableInformativeSnackbar}
				>
					Continuar
				</Button>
			</Line>
		</DialogActions>
	);

	return (
		<Dialog
			open={open}
			keepMounted
			onClose={handleClose}
			maxWidth="md"
			scroll="paper"
			data-testid="termsModalOpen"
			style={{ paddingBottom: 20 }}
		>
			<RenderDialogHeader />
			<RenderDialogContent />
			{isoledComponent ? null : <RenderDialogActions />}
		</Dialog>
	);
};

export default TermsOfUseModal;
