import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Unauthorized from '../Page401';
import { usePO } from '../../utils/POContext';
import WindowTabs from '../../components/WindowTabs';
import BuildSearch from './BuildSearchForm';
import Plataformas from './Platforms';
import { useDispatch } from 'react-redux';
import { changeActiveMenu } from '../../redux/slices/menuSlice';
import SavedSearches from './SavedSearches';
import { useHistory, useLocation } from 'react-router-dom';
import BackDropLoading from '../../components/BackdropLoading';

const Busca: React.FC = () => {
	const { userData } = usePO();
	const { t } = useTranslation();
	const location = useLocation();
	const history = useHistory();

	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState<number | null>(null);

	const renderTab1 = () => <BuildSearch />;
	const renderTab2 = () => <SavedSearches />;
	const renderTab3 = () => <Plataformas />;

	const tabTitles = [
		t('Buscas.Nova Busca'),
		t('Buscas.Buscas Salvas'),
		t('Buscas.Plataformas'),
	];

	const getTabIndexFromPath = (path: string): number => {
		if (path.endsWith('/nova-busca')) return 0;
		if (path.endsWith('/buscas-salvas')) return 1;
		if (path.endsWith('/plataformas')) return 2;

		return 0;
	};

	useEffect(() => {
		dispatch(changeActiveMenu('busca'));
	}, [dispatch]);

	useEffect(() => {
		const tabIndex = getTabIndexFromPath(location.pathname);
		setActiveTab(tabIndex);
	}, [location.pathname]);

	const handleTabChange = (newIndex: number) => {
		const routes = ['/nova-busca', '/buscas-salvas', '/plataformas'];
		history.push(routes[newIndex]);
		setActiveTab(newIndex);
	};

	if (activeTab === null) {
		return <BackDropLoading />;
	}

	return (
		<>
			{userData?.permissions?.indexOf('read:searches') !== -1 ||
			userData.roles?.indexOf('Administrador do cliente') !== -1 ? (
				<WindowTabs
					tab1Component={renderTab1()}
					tab2Component={renderTab2()}
					tab3Component={renderTab3()}
					tab1Title={`${t('Buscas.Nova Busca')}`}
					tab2Title={`${t('Buscas.Buscas Salvas')}`}
					tab3Title={`${t('Buscas.Plataformas')}`}
					tab4Title={`${t('Buscas.Monitoramento')}`}
					windowTitle={tabTitles[activeTab]}
					activeTab={activeTab}
					onTabChange={handleTabChange}
				/>
			) : (
				<div style={{ marginLeft: '-75px' }}>
					<Unauthorized />
				</div>
			)}
		</>
	);
};

export default Busca;
