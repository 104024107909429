import { Typography } from '@mui/material';
import React from 'react';
import { Column, Line } from '../../styles';
import { usePO } from '../../utils/POContext';

interface Props {
	name?: string;
	padding?: string;
	margin?: string;
	background?: string;
	color?: string;
	width?: string;
	opacity?: number;
}

const FormDivider: React.FC<Props> = ({
	name,
	padding,
	background,
	margin,
	width,
	opacity,
}: Props) => {
	const { selectedTheme } = usePO();
	return (
		<Column
			style={{
				padding,
				opacity: 0.5,
				width,
				alignItems: 'center',
			}}
		>
			<Line
				aria-label="line"
				style={{
					background: 'transparent',
					height: 1,
					margin: margin ?? 20,
					borderBottomStyle: 'solid',
					borderBottomColor: 'gray',
					borderBottomWidth: 1,
					opacity: opacity ?? 0.5,
				}}
			>
				<Typography
					variant="subtitle2"
					style={{
						marginLeft: 20,
						background: background || selectedTheme?.background,
						color: selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
						padding: '0 10px',
						opacity: opacity ? opacity * 1.3 : 0.9,
					}}
				>
					{name}
				</Typography>
			</Line>
		</Column>
	);
};
export default FormDivider;
