import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
	Button,
	IconButton,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	Zoom,
} from '@mui/material';
import { ThumbUpRounded } from '@mui/icons-material/';
import { useTranslation } from 'react-i18next';
import LoadingDots from '../../../components/LoadingDots';
import { api, isAxiosError, setupRequestToken } from '../../../utils/api';
import { useAuth0 } from '../../../react-auth0-spa';

import { Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';
import { OfferToolTip } from '../../../helpers';
import { ErrorResponse } from '../../../interfaces';

import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { updateGoodCards } from '../../../redux/slices/cardFeedbackSlice';
import { useCardSelector } from '../../../hooks/Classificacoes/CardSelector';
import { submitClassification } from '../../../services/classifications';

interface Props {
	setSelectedAll: any;
}

const DenounceAllowedDialog: React.FC<Props> = ({ setSelectedAll }: Props) => {
	const { token } = useAuth0();
	const { t } = useTranslation();
	const { selectedTheme, selectedClient } = usePO();
	const { enqueueSnackbar } = useSnackbar();
	const { selectedCards, handleSelectCard } = useCardSelector();

	setupRequestToken(api, token);

	const useStyles = makeStyles(() => ({
		option: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		dialogPaper: {
			maxHeight: '90vh',
		},
	}));

	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const selectedCardIDs = selectedCards.map((el) => el.offerID);

			if (selectedCardIDs.length > 0) {
				const classificationPayload = {
					offerID: selectedCardIDs,
					classificationModel: {
						classificationTypeID: 'PSTLG',
						denounceStatusID: 'CLASSIFICADA',
						clientID: selectedClient.id,
					},
				};

				const response = await submitClassification(classificationPayload);

				if (response?.success === true) {
					enqueueSnackbar(
						t('Classificações.Classificações enviadas com sucesso'),
						{ variant: 'success' }
					);
					dispatch(updateGoodCards(selectedCardIDs));
					handleClose();
					handleSelectCard(null, 'clear');
					setSelectedAll(false);
				} else {
					enqueueSnackbar(t(response?.data.detail), {
						variant: 'warning',
					});
				}
			}
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					enqueueSnackbar(errorResponse?.data?.detail, { variant: 'error' });
				}
			}
		}
	};

	const handleClickPermit = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setLoading(false);
		setSelectedAll([]);
		setOpen(false);
	};

	const handleFooter = () => {
		if (selectedClient !== undefined && selectedClient !== null) {
			return (
				<Line style={{ justifyContent: 'space-between' }}>
					<Button
						size="small"
						onClick={handleClose}
						disabled={loading}
						style={{ color: loading ? 'gray' : selectedTheme.error }}
					>
						{t('Classificações.Cancelar')}
					</Button>
					{loading ? (
						<LoadingDots height={30} width={70} loop />
					) : (
						<Button
							size="small"
							onClick={handleSubmit}
							data-testid="DenounceOffer"
							style={{
								border: `1px solid ${
									selectedTheme.id === 'dark'
										? selectedTheme.footerLine
										: 'transparent'
								}`,
								color:
									selectedTheme.tone === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
							}}
						>
							{t('Classificações.Classificar')}
						</Button>
					)}
				</Line>
			);
		}

		return (
			<Line style={{ justifyContent: 'flex-start' }}>
				<Button
					size="small"
					onClick={handleClose}
					disabled={loading}
					style={{ color: loading ? 'gray' : selectedTheme.error }}
				>
					{t('Classificações.Cancelar')}
				</Button>
			</Line>
		);
	};

	const renderThumbsUp = () => {
		return (
			<OfferToolTip
				title={`${t('Classificações.Permitir')}`}
				aria-label="inativo"
				arrow
				enterDelay={500}
				enterNextDelay={500}
				TransitionComponent={Zoom}
			>
				<IconButton
					sx={{
						'&:hover': {
							backgroundColor:
								selectedTheme.id === 'dark' && selectedTheme.primaryLight,
						},
					}}
					onClick={handleClickPermit}
				>
					<ThumbUpRounded
						fontSize="small"
						style={{
							color: selectedTheme.success,
						}}
					/>
				</IconButton>
			</OfferToolTip>
		);
	};

	const renderContent = () => (
		<DialogContent
			style={{
				background: selectedTheme.id === 'dark' && selectedTheme.overlay6dp,
				padding: '20px 30px',
			}}
		>
			<Line style={{ justifyContent: 'flex-start' }}>
				{selectedCards.length ? (
					<>
						<Typography
							variant="h6"
							style={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							{selectedCards.length === 1
								? `${t('Classificações.Classificar')} ${selectedCards.length}  ${t('Classificações.anúncio como permitido para o cliente')}:`
								: `${t('Classificações.Classificar')} ${selectedCards.length} ${t('Classificações.anúncios como permitidos para o cliente')}:`}
						</Typography>
						<Typography
							variant="h6"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
								marginLeft: '5px',
							}}
						>
							{selectedClient.name || ''}
						</Typography>
						<Typography
							variant="h6"
							sx={{
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							}}
						>
							?
						</Typography>
					</>
				) : null}
			</Line>
		</DialogContent>
	);

	return (
		<div>
			{renderThumbsUp()}
			<Dialog
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
				classes={{ paper: classes.dialogPaper }}
				disableEscapeKeyDown
			>
				{renderContent()}
				<DialogActions
					style={{
						border: `1px solid ${
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#f2f2f2'
						}`,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.overlay3dp
								: selectedTheme.foreground,
						padding: 10,
					}}
				>
					{handleFooter()}
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DenounceAllowedDialog;
