import { Line } from '../../../styles';
import { usePO } from '../../../utils/POContext';

import {
	Stepper,
	Step,
	StepLabel,
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { ErrorResponse } from '../../../interfaces';
import { isAxiosError } from '../../../utils/api';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ClientBranchType,
	ClientType,
	CompanyType,
	CountryType,
	FormErrors,
} from 'src/interfaces/Clients';
import { ArrowBack, ArrowForward, CloseRounded } from '@mui/icons-material';
import ClientContactInfo from './ClientContact';
import ClientBasicInfo from './ClientBasicInfo';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import {
	createNewClient,
	createNewClientBranch,
	createNewClientReasons,
	getClients,
	getReasonsDenouncement,
} from 'src/services/client';
import { onlyNumbersRegex } from 'src/helpers';
import { cnpj } from 'cpf-cnpj-validator';
import LoadingDots from 'src/components/LoadingDots';
import ClientReasonsDenouncements from './ClientReasonsDenouncements';
import countryNames from 'i18n-iso-countries';
import i18n from 'src/i18n';

interface Props {
	open: boolean;
	setOpen: (boolean) => void;
	refresh: () => void;
}

const FormularioCliente: React.FC<Props> = ({
	open,
	setOpen,
	refresh,
}: Props) => {
	const { selectedTheme, countries } = usePO();
	const { t } = useTranslation();

	const [activeStep, setActiveStep] = useState(0);
	const [completedSteps, setCompletedSteps] = useState<number[]>([]);

	const [translatedCountries, setTranslatedCountries] = useState(countries);
	const [parentCompanyName, setParentCompanyName] = useState<
		ClientType | undefined
	>(undefined);
	const [availableClients, setAvailableClients] = useState<ClientType[]>([]);
	const avaliableCliensData = useMemo(() => {
		return availableClients.filter((client) => client.ISactive);
	}, [availableClients]);
	const [avaliableReasonsDenouncement, setAvaliableReasonsDenouncement] =
		useState();
	const [selectedCountry, setSelectedCountry] = useState<
		CountryType | undefined
	>(undefined);

	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [newCompanyBranch, setNewCompanyBranch] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [uploadedFile, setUploadedFile] = useState<any>(null);
	const [companyName, setCompanyName] = useState<CompanyType | undefined>(
		undefined
	);
	const steps = [
		t('Clientes.Informações Básicas'),
		t('Clientes.Informações da Unidade'),
		t('Clientes.Motivos de denúncia'),
	];
	const [formData, setFormData] = useState<ClientBranchType>({
		id: '',
		name: '',
		parent: '',
		clientRegNumber: '',
		clientID: '',
		countryID: '',
		country: '',
		stateName: '',
		cityName: '',
		zipCode: '',
		streetAddress: '',
		numberAddress: '',
		complement: '',
		contactName: '',
		BIID: '',
		email1: '',
		email2: '',
		phone1: '',
		phone2: '',
		description: '',
		ISactive: false,
		reasons: [],
	});

	useEffect(() => {
		if (countries.length > 0) {
			const newCountries = countries.map((country) => {
				const translatedName = countryNames.getName(country.id, i18n.language);
				return {
					...country,
					name: translatedName || country.name,
				};
			});

			setTranslatedCountries(newCountries);
		}
	}, [i18n.language, countries]);

	useEffect(() => {
		if (open) {
			fetchAvailableClients();
			fetchAvailableReasonsDenouncement();
		}
	}, [open]);

	const handleNextStep = async () => {
		try {
			if (activeStep === 0) {
				await validationSchemaClient.validate(formData, { abortEarly: false });
				setFormErrors({});
				setCompletedSteps((prev) => [...prev, activeStep]);
				setActiveStep(1);
			} else if (activeStep === 1) {
				await validationSchemaBranch.validate(formData, { abortEarly: false });
				setFormErrors({});
				setCompletedSteps((prev) => [...prev, activeStep]);
				setActiveStep(2);
			} else if (activeStep === 2) {
				handleSubmitFinal();
			}
		} catch (err) {
			const validationErrors: Record<string, string> = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error) => {
					validationErrors[error.path || ''] = error.message;
				});
				setFormErrors(validationErrors);
			}
		}
	};

	const handleStepClick = (index: number) => {
		if (index <= activeStep || completedSteps.includes(index)) {
			setActiveStep(index);
		}
	};

	const handleBack = () => {
		setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
	};

	const handleClose = () => {
		setOpen(false);
		setActiveStep(0);
		handleResetForm();
		setParentCompanyName(undefined);
	};

	const handleResetForm = () => {
		setFormData({
			id: '',
			name: '',
			parent: '',
			clientRegNumber: '',
			clientID: '',
			countryID: '',
			country: '',
			stateName: '',
			cityName: '',
			zipCode: '',
			streetAddress: '',
			numberAddress: '',
			complement: '',
			contactName: '',
			BIID: '',
			email1: '',
			email2: '',
			phone1: '',
			phone2: '',
			description: '',
			ISactive: false,
			reasons: [],
		});
		setCompanyName(undefined);
		setUploadedFile(null);
		setSelectedCountry(undefined);
		setFormErrors({});
	};

	const fetchAvailableClients = async () => {
		try {
			const usersDataResponse: any = await getClients();
			setAvailableClients(usersDataResponse.data);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					return {
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
					};
				} else {
					return {
						success: false,
						status: 500,
						message:
							'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
					};
				}
			}
		}
	};

	const fetchAvailableReasonsDenouncement = async () => {
		try {
			const response: any = await getReasonsDenouncement();
			setAvaliableReasonsDenouncement(response.data);
		} catch (error) {
			if (isAxiosError(error)) {
				const errorResponse = error.response as ErrorResponse | undefined;
				if (errorResponse && errorResponse.data) {
					return {
						success: false,
						status: errorResponse.status,
						message: errorResponse.data.detail,
					};
				} else {
					return {
						success: false,
						status: 500,
						message:
							'Erro ao enviar a solicitação. Tente novamente ou contate o suporte.',
					};
				}
			}
		}
	};

	const validationSchemaClient = Yup.object().shape({
		name: Yup.string()
			.min(3, t('Clientes.O nome precisa ter no mínimo 3 caracteres'))
			.required(t('Clientes.O nome é obrigatório')),
	});

	const validationSchemaBranch = Yup.object().shape({
		countryID: Yup.string().when('parent', {
			is: (value) => !value,
			then: (schema) => schema.required(t('Clientes.O país é obrigatório')),
			otherwise: (schema) => schema.notRequired(),
		}),
		clientRegNumber: Yup.string()
			.required(t('Clientes.O número de registro ou CNPJ é obrigatório'))
			.test(
				t('Clientes.Validação de número de registro'),
				t('Clientes.Numero de CNPJ inválido'),
				(value) => {
					if (value && formData.countryID === 'BRA') {
						const schemaCnpj = Yup.string().test(
							t('Clientes.Validação de CNPJ'),
							t('Clientes.Numero de CNPJ inválido'),
							(valueCnpj) =>
								cnpj.isValid(valueCnpj || ''.replace(/[^0-9]/g, ''))
						);
						return schemaCnpj.isValidSync(value);
					}
					return true;
				}
			),
		email1: Yup.string()
			.required(t('Clientes.O email é obrigatório'))
			.email(t('Clientes.E-mail inválido')),
		email2: Yup.string().email(t('Clientes.E-mail inválido')),
		contactName: Yup.string()
			.min(
				3,
				t('Clientes.O nome do contato precisa ter no mínimo 3 caracteres')
			)
			.required(t('Clientes.O nome do contato é obrigatório')),
	});

	const validationSchemaReasons = Yup.object().shape({
		reasons: Yup.array()
			.min(1, t('Clientes.Selecione pelo menos um motivo de denúncia'))
			.required(t('Clientes.Selecione pelo menos um motivo de denúncia')),
	});

	const handleSubmitFinal = async () => {
		try {
			// Validate client data
			await validationSchemaClient.validate(formData, { abortEarly: false });

			// Validate branch data
			await validationSchemaBranch.validate(formData, { abortEarly: false });

			// Validate reasons data
			await validationSchemaReasons.validate(formData, { abortEarly: false });

			setFormErrors({});
			setLoading(true);

			// ** PAYLOAD CLIENT **
			const clientPayload = {
				name: formData.name,
				parent: formData.parent,
				description: formData.description,
				ISactive: true,
				BIID: formData.BIID,
				logo: uploadedFile?.file || null,
			};

			let clientID = formData.clientID;
			if (!clientID) {
				const responseClient = await createNewClient(clientPayload);
				if (responseClient?.success) {
					clientID = responseClient.data.id;
					setFormData((prev) => ({ ...prev, clientID }));
					enqueueSnackbar(t('Clientes.Cliente adicionado com sucesso'), {
						variant: 'success',
					});
				} else {
					enqueueSnackbar(
						t('Clientes.Cliente ou código de referência já cadastrado.'),
						{ variant: 'warning' }
					);
					setLoading(false);
					return;
				}
			}

			// ** PAYLOAD COUNTRY **
			const branchPayload = {
				clientID: clientID,
				countryID: formData.countryID,
				clientRegNumber: formData.clientRegNumber.replace(/\D/g, ''),
				zipCode:
					formData.countryID === 'BRA'
						? formData.zipCode.replace(onlyNumbersRegex, '')
						: formData.zipCode,
				streetAddress: formData.streetAddress,
				numberAddress: parseInt(formData.numberAddress.trim(), 10),
				complement: formData.complement,
				cityName: formData.cityName,
				stateName: formData.stateName,
				contactName: formData.contactName,
				parent: formData.parent,
				email: renderEmails(),
				phone: renderPhones(),
				ISactive: true,
				BIID: formData.BIID,
			};

			const responseBranch = await createNewClientBranch(branchPayload);
			if (responseBranch?.success) {
				enqueueSnackbar(
					t('Clientes.Relação entre cliente e país adicionada com sucesso.'),
					{ variant: 'success' }
				);
			} else {
				enqueueSnackbar(
					t(
						'Clientes.Relação entre cliente e país já cadastrada para esse CNPJ'
					),
					{ variant: 'warning' }
				);
			}

			if (formData.reasons?.length > 0) {
				const reasonsPayload = {
					clientID,
					classificationTypeID: formData.reasons,
				};

				const responseReasons = await createNewClientReasons(reasonsPayload);
				if (responseReasons?.success) {
					enqueueSnackbar(
						t(
							'Clientes.Relação entre cliente e tipo de classificação adicionada com sucesso.'
						),
						{
							variant: 'success',
						}
					);
				} else {
					enqueueSnackbar(t('Clientes.Tipo de classificação não encontrado.'), {
						variant: 'warning',
					});
				}
			}

			handleClose();
			refresh();
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error) => {
					validationErrors[error.path || ''] = error.message;
				});
				setFormErrors(validationErrors);
			}
		} finally {
			setLoading(false);
		}
	};

	const renderPhones = () => {
		if (!formData.phone1) {
			return null;
		}
		if (!formData.phone2) {
			return [formData.phone1.replace(onlyNumbersRegex, '')];
		}
		return [
			formData.phone1.replace(onlyNumbersRegex, ''),
			formData.phone2.replace(onlyNumbersRegex, ''),
		];
	};

	const renderEmails = () => {
		if (formData.email1 === '') {
			return null;
		}
		if (formData.email2 === '') {
			return [formData.email1];
		}
		return [formData.email1, formData.email2];
	};

	const typographyStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const handleFormTitle = () => {
		return (
			<Typography
				noWrap
				style={{
					color: typographyStyleHeader(),
					fontSize: 20,
					fontWeight: 'bold',
					maxWidth: '90%',
				}}
			>
				{t('Clientes.Novo Cliente')}
			</Typography>
		);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
			<DialogTitle
				style={{
					height: 60,
					background:
						selectedTheme.id === 'dark'
							? selectedTheme?.tableHead
							: selectedTheme.gradient,
					color: '#FFFFFF',
					display: 'flex',
				}}
				id="draggable-dialog-title"
			>
				<Line
					style={{
						justifyContent: 'space-between',
					}}
				>
					{handleFormTitle()}

					<IconButton
						onClick={handleClose}
						style={{ marginRight: '-16px' }}
						sx={{
							'&:hover': {
								backgroundColor: selectedTheme.hoverBackground,
							},
						}}
					>
						<CloseRounded
							style={{
								color:
									selectedTheme.id === 'main' ? selectedTheme.primary : 'white',
							}}
						/>
					</IconButton>
				</Line>
			</DialogTitle>
			<DialogContent
				style={{
					padding: 22,
					width: '100%',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
				}}
			>
				<Stepper
					activeStep={activeStep}
					alternativeLabel
					sx={{ margin: '10px 0px' }}
				>
					{steps.map((label, index) => (
						<Step key={index} sx={{ position: 'relative' }}>
							<StepLabel
								onClick={
									index < activeStep || completedSteps.includes(index)
										? () => handleStepClick(index)
										: undefined
								}
								sx={{
									cursor:
										index < activeStep || completedSteps.includes(index)
											? 'pointer'
											: 'default',
									'& .Mui-completed .MuiStepIcon-root, & .MuiStepIcon-root:hover':
										{
											cursor: 'pointer',
										},
									'& .MuiStepLabel-root.Mui-disabled': {
										cursor:
											index < activeStep || completedSteps.includes(index)
												? 'pointer'
												: 'default',
									},

									'& .MuiStepLabel-root .Mui-active': {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.primaryDark
												: selectedTheme.primaryDark,
									},
									'& .MuiStepLabel-label.Mui-active': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorHigh,
									},

									'& .MuiStepLabel-label.Mui-completed': {
										color:
											selectedTheme.id === 'dark' &&
											selectedTheme.textColorMedium,
									},
									'& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
										color:
											index === activeStep
												? selectedTheme.id === 'dark'
													? selectedTheme.textColorHigh
													: ''
												: selectedTheme.id === 'dark'
													? selectedTheme.textColorMedium
													: 'inherit',
									},

									'& .MuiStepIcon-root': {
										color:
											index === activeStep
												? selectedTheme.id === 'dark'
													? `${selectedTheme.primaryDark} !important`
													: `${selectedTheme.primaryDark} !important`
												: index < activeStep
													? selectedTheme.id === 'dark'
														? `${selectedTheme.success} !important`
														: `${selectedTheme.success} !important`
													: selectedTheme.id === 'dark'
														? `${selectedTheme.primaryDark} !important`
														: `${selectedTheme.primaryDark} !important`,
									},

									'& .MuiStepIcon-root:hover': {
										cursor: index < activeStep ? 'pointer' : 'default',
										color:
											index < activeStep
												? selectedTheme.id === 'dark'
													? selectedTheme.overlay4dp
													: selectedTheme.primaryLight
												: selectedTheme.id === 'dark'
													? selectedTheme.overlay3dp
													: selectedTheme.primaryDark,
									},
								}}
							>
								{label}
							</StepLabel>
						</Step>
					))}
				</Stepper>

				{activeStep === 0 && (
					<ClientBasicInfo
						availableClientsData={avaliableCliensData}
						formData={formData}
						setFormData={setFormData}
						formErrors={formErrors}
						parentCompanyName={parentCompanyName}
						setParentCompanyName={setParentCompanyName}
						companyName={companyName}
						setCompanyName={setCompanyName}
						uploadedFile={uploadedFile}
						setUploadedFile={setUploadedFile}
					/>
				)}

				{activeStep === 1 && (
					<ClientContactInfo
						translatedCountries={translatedCountries}
						formData={formData}
						setFormData={setFormData}
						formErrors={formErrors}
						newCompanyBranch={newCompanyBranch}
						setNewCompanyBranch={setNewCompanyBranch}
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
					/>
				)}
				{activeStep === 2 && (
					<ClientReasonsDenouncements
						formData={formData}
						setFormData={setFormData}
						avaliableReasonsDenouncement={avaliableReasonsDenouncement}
						formErrors={formErrors}
					/>
				)}
			</DialogContent>

			<DialogActions
				style={{
					borderTop: 'solid',
					borderTopWidth: 1,
					borderTopColor:
						selectedTheme.id === 'dark' ? selectedTheme?.footerLine : '#eaeaea',
					background:
						selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
				}}
			>
				<Line
					style={{
						justifyContent: 'space-between',
						padding: '5px 10px',
					}}
				>
					{activeStep === 0 ? (
						<Button
							onClick={handleClose}
							style={{ color: selectedTheme.error }}
						>
							{t('Clientes.Cancelar')}
						</Button>
					) : (
						<Button
							onClick={handleBack}
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
							}}
							startIcon={<ArrowBack />}
						>
							{t('Clientes.Voltar')}
						</Button>
					)}

					{loading ? (
						<LoadingDots width={100} height={30} loop />
					) : (
						<Button
							onClick={
								activeStep === steps.length - 1
									? handleSubmitFinal
									: handleNextStep
							}
							variant="contained"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.foreground,
								background: selectedTheme.primaryDark,
								border: handleBorderButton(),
							}}
							endIcon={activeStep !== steps.length - 1 && <ArrowForward />}
						>
							{activeStep === steps.length - 1
								? t('Clientes.Adicionar')
								: t('Clientes.Próximo')}
						</Button>
					)}
				</Line>
			</DialogActions>
		</Dialog>
	);
};

export default FormularioCliente;
