import { Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';

import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Zoom,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientBranchType, FormErrors } from 'src/interfaces/Clients';
import { CloseRounded, BorderColorRounded } from '@mui/icons-material';
import ClientContactInfo from '../../ClientNewForm/ClientContact';
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { editClientBranch } from 'src/services/client';
import { OfferToolTip, onlyNumbersRegex } from 'src/helpers';
import { cnpj } from 'cpf-cnpj-validator';
import LoadingDots from 'src/components/LoadingDots';
import countryNames from 'i18n-iso-countries';

interface Props {
	rowData: any;
	refresh: () => void;
}

const ClientEditBranch: React.FC<Props> = ({ rowData, refresh }: Props) => {
	const { selectedTheme, countries } = usePO();
	const { t, i18n } = useTranslation();

	const [selectedCountry, setSelectedCountry] = useState(null);
	const [translatedCountries, setTranslatedCountries] = useState(countries);

	const [open, setOpen] = useState(false);
	const [formErrors, setFormErrors] = useState<FormErrors>({});
	const [newCompanyBranch, setNewCompanyBranch] = useState<string | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const [formData, setFormData] = useState<ClientBranchType>({
		id: rowData.id || '',
		name: rowData.name || '',
		parent: rowData.parent,
		clientRegNumber: rowData.clientRegNumber || '',
		clientID: rowData.clientID || '',
		countryID: rowData.countryID || '',
		country: rowData.country || '',
		stateName: rowData.stateName || '',
		cityName: rowData.cityName || '',
		zipCode: rowData.zipCode || '',
		streetAddress: rowData.streetAddress || '',
		numberAddress: rowData.numberAddress || '',
		complement: rowData.complement || '',
		contactName: rowData.contactName || '',
		BIID: rowData.BIID || '',
		email1: rowData.email1 || '',
		email2: rowData.email2 || '',
		phone1: rowData.phone1 || '',
		phone2: rowData.phone2 || '',
		description: rowData.description || '',
		ISactive: false,
		reasons: [],
	});

	useEffect(() => {
		if (countries.length > 0) {
			const newCountries = countries.map((country) => {
				const translatedName = countryNames.getName(country.id, i18n.language);
				return {
					...country,
					name: translatedName || country.name,
				};
			});

			setTranslatedCountries(newCountries);
		}
	}, [i18n.language, countries]);

	useEffect(() => {
		if (rowData && translatedCountries.length > 0) {
			setFormData((prev) => ({
				...prev,
				email1: rowData.email ? rowData.email[0] || '' : '',
				email2: rowData.email ? rowData.email[1] || '' : '',
				phone1: rowData.phone ? rowData.phone[0] || '' : '',
				phone2: rowData.phone ? rowData.phone[1] || '' : '',
			}));

			const country = translatedCountries.find(
				(country) => country.id === rowData.countryID
			);
			if (country) setSelectedCountry(country);
		}
	}, [rowData, translatedCountries]);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		handleResetForm();
	};

	const handleResetForm = () => {
		setFormErrors({});
	};

	const validationSchemaBranch = Yup.object().shape({
		countryID: Yup.string().when('parent', {
			is: (value) => !value,
			then: (schema) => schema.required(t('Clientes.O país é obrigatório')),
			otherwise: (schema) => schema.notRequired(),
		}),
		clientRegNumber: Yup.string()
			.required(t('Clientes.O número de registro ou CNPJ é obrigatório'))
			.test(
				t('Clientes.Validação de número de registro'),
				t('Clientes.Numero de CNPJ inválido'),
				(value) => {
					if (value && formData.countryID === 'BRA') {
						const schemaCnpj = Yup.string().test(
							t('Clientes.Validação de CNPJ'),
							t('Clientes.Numero de CNPJ inválido'),
							(valueCnpj) =>
								cnpj.isValid(valueCnpj || ''.replace(/[^0-9]/g, ''))
						);
						return schemaCnpj.isValidSync(value);
					}
					return true;
				}
			),
		email1: Yup.string()
			.required(t('Clientes.O email é obrigatório'))
			.email(t('Clientes.E-mail inválido')),
		email2: Yup.string().email(t('Clientes.E-mail inválido')),
		contactName: Yup.string()
			.min(
				3,
				t('Clientes.O nome do contato precisa ter no mínimo 3 caracteres')
			)
			.required(t('Clientes.O nome do contato é obrigatório')),
	});

	const handleSubmitFinal = async () => {
		try {
			await validationSchemaBranch.validate(formData, { abortEarly: false });

			setFormErrors({});
			setLoading(true);

			// ** PAYLOAD COUNTRY **
			const branchPayload = {
				clientID: rowData.clientID,
				countryID: formData.countryID,
				clientRegNumber: formData.clientRegNumber.replace(/\D/g, ''),
				zipCode:
					formData.countryID === 'BRA'
						? formData.zipCode.replace(onlyNumbersRegex, '')
						: formData.zipCode,
				streetAddress: formData.streetAddress,
				numberAddress: parseInt(formData.numberAddress.trim(), 10),
				complement: formData.complement,
				cityName: formData.cityName,
				stateName: formData.stateName,
				contactName: formData.contactName,
				parent: formData.parent,
				email: renderEmails(),
				phone: renderPhones(),
				ISactive: true,
				BIID: formData.BIID,
			};

			const responseBranch = await editClientBranch(branchPayload, rowData.id);
			if (responseBranch?.success) {
				enqueueSnackbar(
					t('Clientes.Relação entre cliente e país adicionada com sucesso.'),
					{ variant: 'success' }
				);
				refresh();
			} else {
				enqueueSnackbar(
					t(
						'Clientes.Relação entre cliente e país já cadastrada para esse CNPJ'
					),
					{ variant: 'warning' }
				);
			}

			handleClose();
		} catch (err) {
			const validationErrors = {};
			if (err instanceof Yup.ValidationError) {
				err.inner.forEach((error) => {
					validationErrors[error.path || ''] = error.message;
				});
				setFormErrors(validationErrors);
			}
		} finally {
			setLoading(false);
		}
	};

	const renderPhones = () => {
		if (!formData.phone1) {
			return null;
		}
		if (!formData.phone2) {
			return [formData.phone1.replace(onlyNumbersRegex, '')];
		}
		return [
			formData.phone1.replace(onlyNumbersRegex, ''),
			formData.phone2.replace(onlyNumbersRegex, ''),
		];
	};

	const renderEmails = () => {
		if (!formData.email1 || formData.email1.length === 0) {
			return null;
		}
		if (formData.email2 === '') {
			return [formData.email1];
		}
		return [formData.email1, formData.email2];
	};

	const typographyStyleHeader = () => {
		switch (selectedTheme.id) {
			case 'main':
				return selectedTheme.primaryDark;
			case 'dark':
				return selectedTheme.textColorHigh;
			default:
				return selectedTheme.foreground;
		}
	};

	const handleBorderButton = () => {
		switch (selectedTheme.id) {
			case 'dark':
				return `1px solid ${selectedTheme.footerLine}`;
			default:
				return '';
		}
	};

	const handleFormTitle = () => {
		return (
			<Typography
				noWrap
				style={{
					color: typographyStyleHeader(),
					fontSize: 20,
					fontWeight: 'bold',
					maxWidth: '90%',
				}}
			>
				{`${t('Clientes.Editar Filial')}: ${
					rowData.clientID
						? rowData.clientID.charAt(0).toUpperCase() +
							rowData.clientID.slice(1)
						: ''
				}`}
			</Typography>
		);
	};

	return (
		<div>
			<IconButton
				onClick={handleOpen}
				disabled={!rowData?.clientID}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<OfferToolTip
					title={`${t('Clientes.Editar Filial')}`}
					aria-label="inativo"
					enterDelay={700}
					enterNextDelay={700}
					arrow
					TransitionComponent={Zoom}
				>
					<BorderColorRounded
						style={{
							fontSize: 20,
							color:
								selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
						}}
					/>
				</OfferToolTip>
			</IconButton>
			<Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
				<DialogTitle
					style={{
						height: 60,
						background:
							selectedTheme.id === 'dark'
								? selectedTheme?.tableHead
								: selectedTheme.gradient,
						color: '#FFFFFF',
						display: 'flex',
					}}
					id="draggable-dialog-title"
				>
					<Line
						style={{
							justifyContent: 'space-between',
						}}
					>
						{handleFormTitle()}

						<IconButton
							onClick={handleClose}
							style={{ marginRight: '-16px' }}
							sx={{
								'&:hover': {
									backgroundColor: selectedTheme.hoverBackground,
								},
							}}
						>
							<CloseRounded
								style={{
									color:
										selectedTheme.id === 'main'
											? selectedTheme.primary
											: 'white',
								}}
							/>
						</IconButton>
					</Line>
				</DialogTitle>
				<DialogContent
					style={{
						padding: 22,
						width: '100%',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
					}}
				>
					<ClientContactInfo
						translatedCountries={translatedCountries}
						formData={formData}
						setFormData={setFormData}
						formErrors={formErrors}
						newCompanyBranch={newCompanyBranch}
						setNewCompanyBranch={setNewCompanyBranch}
						selectedCountry={selectedCountry}
						setSelectedCountry={setSelectedCountry}
					/>
				</DialogContent>

				<DialogActions
					style={{
						borderTop: 'solid',
						borderTopWidth: 1,
						borderTopColor:
							selectedTheme.id === 'dark'
								? selectedTheme?.footerLine
								: '#eaeaea',
						background:
							selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
					}}
				>
					<Line
						style={{
							justifyContent: 'space-between',
							padding: '5px 10px',
						}}
					>
						<Button
							onClick={handleClose}
							style={{ color: selectedTheme.error }}
						>
							{t('Cancelar')}
						</Button>

						{loading ? (
							<LoadingDots width={100} height={30} loop />
						) : (
							<Button
								onClick={handleSubmitFinal}
								variant="contained"
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: selectedTheme.foreground,
									background: selectedTheme.primaryDark,
									border: handleBorderButton(),
								}}
							>
								{t('Clientes.Adicionar')}
							</Button>
						)}
					</Line>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ClientEditBranch;
