import React, { useEffect, useState } from 'react';
import {
	Box,
	MenuItem,
	Typography,
	Zoom,
	Autocomplete,
	TextField,
	Select,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Column, Line } from '../../../../styles';
import { usePO } from '../../../../utils/POContext';
import { OfferToolTip } from '../../../../helpers';
import { makeStyles } from '@mui/styles';
import FormErrorIcon from '../../../../components/FormErrorIcon';
import { PeriodicityUnit } from 'src/interfaces/Searches';

interface Props {
	disableSeachType?: boolean;
	periodicity: any;
	setPeriodicity: (data) => any;
	handleChangePeriodicityUnit: () => void;
	periodicityUnit: PeriodicityUnit;
	searchType: any;
	setSearchType: (data) => any;
	setDescription?: (data: string) => void;
	description?: string;
	searchTypeOptions: any;
	setExistentTag?: any;
	existentTag?: any;
	displayExistentTag?: any;
	formErrors?: any;
	selectedPlatforms: any;
	setSelectedPlatforms: (data) => void;
	buildingSearch?: boolean;
	isEditing?: boolean;
	isUniqueSearch: boolean;
	setIsUniqueSearch: (data: boolean) => void;
	renderKey: number;
	selectedDomain: boolean;
	setSelectedDomain: (data: boolean) => void;
}

const RightColumn: React.FC<Props> = ({
	periodicity,
	setPeriodicity,
	handleChangePeriodicityUnit,
	periodicityUnit,
	searchType,
	disableSeachType,
	setSearchType,
	searchTypeOptions,
	setExistentTag,
	existentTag,
	displayExistentTag,
	formErrors,
	setDescription,
	description,
	isEditing,
	isUniqueSearch,
	setIsUniqueSearch,
	renderKey,
	selectedDomain,
	setSelectedDomain,
}: Props) => {
	const { t } = useTranslation();
	const { selectedClient, searchTagsActiveClient, selectedTheme } = usePO();

	const [currentSearchType, setCurrentSearchType] = useState(searchType);
	const [lastPeriodicity, setLastPeriodicity] = useState(periodicity);

	const handleCheckboxChange = (event) => {
		const isChecked = event.target.checked;

		if (isChecked) {
			setLastPeriodicity(periodicity);
			setPeriodicity('-1');
			setIsUniqueSearch(isChecked);
		} else {
			setPeriodicity(lastPeriodicity);
			setIsUniqueSearch(false);
		}
	};

	const useStyles = makeStyles(() => ({
		periodicityStyle: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
		menuItem: {
			backgroundColor:
				selectedTheme.id === 'dark'
					? selectedTheme.overlay4dp
					: selectedTheme.foreground,
			'&:focus': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground,
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay8dp
						: selectedTheme.background,
			},
		},
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		textFieldStyle: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
		},
		dialogPaper: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme.overlay8dp : '',
			'& .MuiSvgIcon-root': {
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			},
		},
		renderDenounceStyles: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
			borderBottom: `1px solid ${
				selectedTheme.id === 'dark'
					? selectedTheme.textColorHigh
					: selectedTheme.border
			}`,
			'& .MuiSvgIcon-root': {
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			},
			'&:hover': {
				backgroundColor:
					selectedTheme.id === 'dark'
						? selectedTheme.overlay3dp
						: selectedTheme.foreground,
				borderBottom: `1px solid ${
					selectedTheme.id === 'dark'
						? selectedTheme.textColorHigh
						: selectedTheme.border
				}`,
			},
		},
		renderDenounceReason: {
			background: selectedTheme.id === 'dark' ? selectedTheme.overlay3dp : '',
		},
	}));

	const classes = useStyles();

	useEffect(() => {
		if (selectedDomain) {
			const dominioOption = searchTypeOptions.find(
				(el) => el.value === 'DOMINIOS'
			);

			if (dominioOption) {
				setCurrentSearchType(dominioOption);
				setSearchType(dominioOption);
			}
		} else {
			setCurrentSearchType((prev) => prev || searchTypeOptions[0]);
			setSearchType((prev) => prev || searchTypeOptions[0]);
		}
	}, [selectedDomain]);

	useEffect(() => {
		if (displayExistentTag) {
			setExistentTag(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedClient]);

	const handleSearchTypeChange = (event) => {
		const selectedOption = searchTypeOptions.find(
			(el) => el.value === event.target.value
		);

		if (selectedOption) {
			setCurrentSearchType(selectedOption);
			setSearchType(selectedOption);

			if (selectedOption.value === 'DOMINIOS') {
				setSelectedDomain(true);
			} else {
				setSelectedDomain(false);
			}
		}
	};

	const handlePeriodicityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.value;
		if (/^\d*$/.test(newValue)) {
			setLastPeriodicity(periodicity);
			setPeriodicity(newValue);
		}
	};

	const renderExistentTag = () => {
		if (displayExistentTag) {
			return (
				<Column
					style={{
						alignItems: 'start',
						rowGap: 14,
					}}
				>
					<Box
						overflow="hidden"
						textOverflow="ellipsis"
						whiteSpace="nowrap"
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<OfferToolTip
							placement="right"
							title={`${t(
								'Buscas.Tabs.Nova Busca.Chave de busca já existente'
							)}`}
							aria-label="Chave de busca já existente"
							arrow
							enterDelay={200}
							enterNextDelay={200}
							TransitionComponent={Zoom}
						>
							<Typography
								noWrap
								style={{
									color:
										selectedTheme.id === 'dark'
											? selectedTheme.textColorHigh
											: '',
								}}
							>
								{t('Buscas.Tabs.Nova Busca.Chave de busca já existente')}:
							</Typography>
						</OfferToolTip>
						<FormErrorIcon formErrorMessage={formErrors?.selectedExistentTag} />
					</Box>
					<Autocomplete
						id="tag-select"
						size="small"
						style={{ width: '100%' }}
						value={existentTag}
						options={searchTagsActiveClient}
						onChange={(_, newValue) => {
							setExistentTag(newValue);
						}}
						autoComplete
						autoSelect
						getOptionLabel={(option) => (option.id ? option.id.toString() : '')}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						renderOption={(props, option) => (
							<li {...props}>{option.id as string}</li>
						)}
						classes={{
							option: classes.autoCompleteStyle,
							paper: classes.autoCompleteStyle,
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								name="selectedExistentTag"
								variant="outlined"
								error={!!formErrors?.selectedExistentTag}
								inputProps={{
									...params.inputProps,
									style: {
										color:
											selectedTheme.id === 'dark'
												? selectedTheme?.textColorMedium
												: '',
									},
								}}
								sx={{
									'.MuiFormHelperText-root.Mui-error': {
										mt: '0px',
										color: selectedTheme.error,
										borderColor: selectedTheme.error,
										position: 'absolute',
										bottom: '-21px',
									},
									'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
										{
											borderColor: selectedTheme.error,
										},
									'& .MuiInputBase-root': {
										background:
											selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
										},
										height: 45,
										':hover': {
											'& > fieldset': {
												borderColor:
													selectedTheme.id === 'dark' ? '#fff' : '#000',
											},
										},
									},
									'.MuiSvgIcon-root ': {
										fill:
											selectedTheme.id === 'dark' &&
											selectedTheme?.textColorMedium,
									},
								}}
							/>
						)}
					/>
				</Column>
			);
		}
		return null;
	};

	return (
		<Column
			style={{
				height: '100%',
				alignItems: 'start',
				justifyContent: 'space-between',
				paddingLeft: '30px',
				color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
			}}
		>
			<Line
				style={{
					height: '100%',
					overflow: 'hidden',
					alignItems: 'start',
					color: selectedTheme.id === 'dark' ? selectedTheme.primaryDark : '',
				}}
			>
				{renderExistentTag()}
			</Line>

			<Line
				style={{
					display: 'flex',
					alignItems: 'flex-end',
					gap: '20px',
					marginTop: isEditing ? 0 : 32,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: 14,
					}}
				>
					<Line style={{ justifyContent: 'space-between' }}>
						<Typography>
							{t('Buscas.Tabs.Nova Busca.Periodicidade')}:
						</Typography>
						<FormErrorIcon formErrorMessage={formErrors?.periodicity} />
					</Line>
					<TextField
						size="small"
						name="periodicity"
						aria-label="periodicity"
						type="text"
						value={isUniqueSearch ? '' : periodicity}
						onChange={handlePeriodicityChange}
						variant="outlined"
						disabled={isUniqueSearch}
						error={!!formErrors?.periodicity}
						inputProps={{ maxLength: 2 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{isUniqueSearch ? (
										<Typography
											style={{
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorMedium
														: '#c4c4c4',
											}}
										>
											{t('Buscas.Tabs.Nova Busca.Única')}
										</Typography>
									) : (
										<Button
											onClick={handleChangePeriodicityUnit}
											sx={{
												padding: '0px',
												color:
													selectedTheme.id === 'dark'
														? selectedTheme.textColorHigh
														: '#000',
												'&:hover': {
													backgroundColor:
														selectedTheme.id === 'dark' &&
														selectedTheme.primaryLight,
												},
											}}
										>
											{periodicityUnit === 'days'
												? Number(periodicity) <= 1
													? t('Buscas.Tabs.Nova Busca.Dia')
													: t('Buscas.Tabs.Nova Busca.Dias')
												: Number(periodicity) <= 1
													? t('Buscas.Tabs.Nova Busca.Hora')
													: t('Buscas.Tabs.Nova Busca.Horas')}
										</Button>
									)}
								</InputAdornment>
							),
						}}
						sx={{
							width: '100%',
							'.MuiFormHelperText-root.Mui-error': {
								mt: '0px',
								color: selectedTheme.error,
								borderColor: selectedTheme.error,
							},
							'& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
								{
									borderColor: selectedTheme.error,
								},
							'& .MuiInputBase-root.Mui-disabled': {
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
							},
							'& .MuiInputBase-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
								height: 45,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
					/>
				</div>

				<FormControlLabel
					style={{ marginRight: 0, gap: 5 }}
					control={
						<Checkbox
							checked={isUniqueSearch}
							onChange={handleCheckboxChange}
							color="primary"
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primaryDark,
							}}
						/>
					}
					label={
						<Typography
							style={{
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: selectedTheme.primary,
								whiteSpace: 'nowrap',
							}}
						>
							{t('Buscas.Tabs.Nova Busca.Busca única')}
						</Typography>
					}
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						flexGrow: 1,
						gap: 14,
					}}
				>
					<Typography style={{ whiteSpace: 'nowrap' }}>
						{t('Buscas.Tabs.Nova Busca.Tipo de Busca')}:
					</Typography>
					<Select
						size="small"
						name="searchType"
						variant="outlined"
						aria-label="searchType"
						key={currentSearchType.value}
						defaultValue={currentSearchType.value}
						onChange={handleSearchTypeChange}
						disabled={disableSeachType || selectedDomain}
						classes={{
							select: classes.periodicityStyle,
							root: classes.menuItem,
						}}
						MenuProps={{
							MenuListProps: {
								disablePadding: true,
							},
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						style={{
							background:
								selectedTheme.id === 'dark'
									? selectedTheme.overlay3dp
									: selectedTheme.foreground,
						}}
						sx={{
							width: '100%',
							height: '45px',
							'& .MuiInputBase-input.Mui-disabled': {
								WebkitTextFillColor:
									selectedTheme.id === 'dark' && selectedTheme.textColorDisable,
							},
							'&.Mui-disabled': {
								'.MuiOutlinedInput-notchedOutline': {
									borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
								},
								'&:hover .MuiOutlinedInput-notchedOutline': {
									borderColor: selectedTheme.id === 'dark' ? '#474747' : '',
								},
								'.MuiSelect-icon': {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorDisable,
								},
							},
							'.MuiOutlinedInput-notchedOutline': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							'&:hover .MuiOutlinedInput-notchedOutline': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
							'.MuiSelect-select': {
								background:
									selectedTheme.id === 'dark'
										? selectedTheme.overlay3dp
										: selectedTheme.foreground,
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorHigh,
							},
							'.MuiSelect-icon': {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme.textColorHigh
										: '',
							},
							'& [aria-expanded=true]': {
								background:
									selectedTheme.id === 'dark' ? selectedTheme.overlay6dp : '',
							},
						}}
						inputProps={{
							MenuProps: {
								MenuListProps: {
									disablePadding: true,
								},
								PaperProps: {
									sx: {
										background:
											selectedTheme.id === 'dark'
												? selectedTheme.overlay3dp
												: selectedTheme.foreground,
										color:
											selectedTheme.id === 'dark'
												? selectedTheme.textColorHigh
												: '',
									},
								},
							},
						}}
					>
						{searchTypeOptions.map((el) => (
							<MenuItem
								classes={{
									selected: classes.menuItem,
									root: classes.menuItem,
								}}
								key={el.name}
								value={el.value}
							>
								{el.name}
							</MenuItem>
						))}
					</Select>
				</div>
			</Line>
			<Line
				style={{
					marginTop: 32,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: 14,
						flexGrow: 1,
					}}
				>
					<Typography>{t('Buscas.Tabs.Nova Busca.Cliente')}:</Typography>

					<TextField
						size="small"
						variant="outlined"
						name="selectedClient"
						value={selectedClient?.name}
						sx={{
							fontWeight: 'bold',
							width: '100%',
							'& .MuiInputBase-root': {
								height: 45,
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},

								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
						InputProps={{
							readOnly: true,
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						classes={{
							root: classes.textFieldStyle,
						}}
					/>
				</div>
			</Line>

			<Line
				style={{
					marginTop: selectedDomain ? 32 : 27,
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						gap: 14,
						flexGrow: 1,
					}}
				>
					<Typography>{t('Buscas.Tabs.Nova Busca.Descrição')}:</Typography>

					<TextField
						key={`Key-${renderKey}`}
						variant="outlined"
						size="small"
						aria-label="description"
						name="description"
						value={description}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setDescription?.(event.target.value);
						}}
						autoComplete="off"
						multiline
						rows={selectedDomain ? 1 : 6}
						InputProps={{
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						sx={{
							width: '100%',
							'& .MuiInputBase-root': {
								height: selectedDomain ? 45 : '100%',
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
						}}
					/>
				</div>
			</Line>
		</Column>
	);
};
export default RightColumn;
