import { Line } from 'src/styles';
import { makeStyles } from '@mui/styles';
import { ClientBranchType, ClientType } from 'src/interfaces/Clients';
import {
	Autocomplete,
	createFilterOptions,
	IconButton,
	TextField,
	Zoom,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePO } from 'src/utils/POContext';
import FileCard from 'src/components/FileCard';
import FullSizeImageDialog from 'src/components/FullSizeImageModal';
import { Delete, LaunchOutlined } from '@mui/icons-material';
import { OfferToolTip } from 'src/helpers';
import FormDivider from 'src/components/FormDivider';

interface Props {
	availableClientsData: any;
	formData: ClientBranchType;
	setFormData: (ClientBranchType) => void;
	formErrors?: any;
	parentCompanyName: any;
	setParentCompanyName: (string) => void;
	companyName: any;
	setCompanyName: (string) => void;
	uploadedFile: any;
	setUploadedFile: any;
}

const ClientBasicInfo: React.FC<Props> = ({
	availableClientsData,
	formData,
	setFormData,
	formErrors,
	parentCompanyName,
	setParentCompanyName,
	companyName,
	uploadedFile,
	setUploadedFile,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();
	const filter = createFilterOptions<any>();

	const [openFileModal, setOpenFileModal] = useState(false);

	const useStyles = makeStyles(() => ({
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		noOptions: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
	}));

	const classes = useStyles();

	const actionElement1 = () => (
		<OfferToolTip
			title={`${t('Clientes.Deletar arquivo')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setUploadedFile(null)}
				disabled={uploadedFile === null}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<Delete
					style={{
						fontSize: 23,
						color:
							uploadedFile !== null
								? selectedTheme.error
								: selectedTheme.id === 'dark'
									? '#4F4F4F'
									: 'lightgray',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	const actionElement2 = () => (
		<OfferToolTip
			title={`${t('Clientes.Visualizar arquivo')}`}
			aria-label="inativo"
			enterDelay={700}
			enterNextDelay={700}
			arrow
			TransitionComponent={Zoom}
		>
			<IconButton
				onClick={() => setOpenFileModal(true)}
				disabled={uploadedFile === null}
				sx={{
					'&:hover': {
						backgroundColor:
							selectedTheme.id === 'dark' && selectedTheme.primaryLight,
					},
				}}
			>
				<LaunchOutlined
					style={{
						fontSize: 23,
						color:
							uploadedFile !== null
								? selectedTheme.id === 'dark'
									? selectedTheme.textColorHigh
									: selectedTheme.lightTextColor
								: selectedTheme.id === 'dark'
									? selectedTheme.textColorMedium
									: '#4F4F4F',
					}}
				/>
			</IconButton>
		</OfferToolTip>
	);

	return (
		<div style={{ marginTop: 20 }}>
			<Autocomplete
				value={parentCompanyName}
				options={availableClientsData.filter(
					(client) => client.name !== companyName?.name
				)}
				onChange={(_, newValue) => {
					if (!newValue) {
						setParentCompanyName(null);
						setFormData((prev) => ({
							...prev,
							parent: '',
						}));
						return;
					}
					setParentCompanyName(newValue);
					setFormData((prev) => ({
						...prev,
						parent: newValue?.id || '',
					}));
				}}
				classes={{
					option: classes.autoCompleteStyle,
					paper: classes.autoCompleteStyle,
					noOptions: classes.noOptions,
				}}
				id="clientsParentField"
				getOptionLabel={(option) => option.name}
				renderOption={(props, option) => <li {...props}>{option.name}</li>}
				isOptionEqualToValue={(
					option: ClientType,
					value: ClientType | undefined
				) => option.id === value?.id}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);

					const typedName = formData.name?.toLowerCase().trim();

					const filteredOptions = filtered.filter(
						(option) => option.name.toLowerCase() !== typedName
					);

					return filteredOptions;
				}}
				handleHomeEndKeys
				noOptionsText={t('Clientes.Nenhuma opção disponível')}
				renderInput={(params) => (
					<TextField
						{...params}
						name="parent"
						label={t('Clientes.Pai')}
						size="small"
						value={formData?.parent}
						placeholder={t(
							'Clientes.Escolha uma empresa da lista para adicionar como pai'
						)}
						variant="outlined"
						onChange={(e) => {
							setFormData({ ...formData, parent: e.target.value });
						}}
						InputLabelProps={{
							shrink: true,
							style: {
								color:
									selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
							},
						}}
						inputProps={{
							...params.inputProps,
							autoComplete: 'new-password', // disable autocomplete and autofill
							style: {
								color:
									selectedTheme.id === 'dark'
										? selectedTheme?.textColorMedium
										: '',
							},
						}}
						sx={{
							'& .MuiInputBase-root': {
								background:
									selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
								'& > fieldset': {
									borderColor:
										selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
								},
								':hover': {
									'& > fieldset': {
										borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
									},
								},
							},
							'.MuiSvgIcon-root ': {
								fill:
									selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
							},
						}}
					/>
				)}
			/>
			<Line
				style={{
					marginTop: 20,
				}}
			>
				<TextField
					name="name"
					label={t('Clientes.Nome')}
					size="small"
					value={formData.name || ''}
					error={!!formErrors?.name}
					helperText={formErrors?.name}
					variant="outlined"
					onChange={(e) => {
						setFormData({ ...formData, name: e.target.value });
					}}
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					inputProps={{
						autoComplete: 'new-password', // disable autocomplete and autofill
						style: {
							color:
								selectedTheme.id === 'dark'
									? selectedTheme?.textColorMedium
									: '',
						},
					}}
					sx={{
						width: '100%',
						'.MuiFormHelperText-root.Mui-error': {
							mt: '0px',
							color: selectedTheme.error,
							borderColor: selectedTheme.error,
							position: 'absolute',
							bottom: '-21px',
						},
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
						'.MuiSvgIcon-root ': {
							fill:
								selectedTheme.id === 'dark' && selectedTheme?.textColorMedium,
						},
					}}
				/>
			</Line>
			<Line
				style={{
					marginTop: 30,
				}}
			>
				<TextField
					variant="outlined"
					multiline
					rows="2"
					name="description"
					autoComplete="off"
					label={t('Clientes.Descrição')}
					disabled={!!formData.clientID}
					value={formData?.description}
					onChange={(e) => {
						setFormData &&
							setFormData({ ...formData, description: e.target.value });
					}}
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '100%',
						'& .MuiInputBase-root': {
							'&.Mui-disabled': {
								'.MuiOutlinedInput-notchedOutline': {
									borderColor:
										selectedTheme.id === 'dark' ? '#474747' : '#d3d3d3',
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			<Line
				style={{
					marginTop: 20,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					name="complement"
					value={formData?.BIID}
					label={t('Clientes.Relatório Superset')}
					onChange={(e) => {
						setFormData({ ...formData, BIID: e.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '100%',
						'& .MuiInputBase-root': {
							'&.Mui-disabled': {
								'.MuiOutlinedInput-notchedOutline': {
									borderColor:
										selectedTheme.id === 'dark' ? '#474747' : '#d3d3d3',
								},
							},
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			<FormDivider
				name={t('Clientes.Logo')}
				background={
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground
				}
			/>
			<Line style={{ width: '100%' }}>
				<FileCard
					setUploadedFile={setUploadedFile}
					customMessage={
						uploadedFile
							? uploadedFile?.name
							: t('Clientes.Clique no ícone ou arraste um arquivo para cá')
					}
					cardActions
					actions1={actionElement1()}
					actions2={actionElement2()}
				/>
				<FullSizeImageDialog
					open={openFileModal}
					setOpen={setOpenFileModal}
					imageIndexToShow={0}
					images={uploadedFile === null ? '' : uploadedFile?.preview}
				/>
			</Line>
		</div>
	);
};

export default ClientBasicInfo;
