import React from 'react';
import { Box } from '@mui/material';
import { OfferToolTip } from '../../../helpers';

interface Props {
	logs: any;
}

const LogsChart: React.FC<Props> = ({ logs }: Props) => {
	const logsArray = Array.isArray(logs) ? logs : Object.values(logs || {});

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				gap: '5px',
				alignItems: 'flex-end',
			}}
		>
			{logsArray.map((log, index) => (
				<OfferToolTip
					key={index}
					title={
						log.date ? new Date(log.date).toLocaleString().replace(',', '') : ''
					}
					placement="bottom"
					arrow
				>
					<Box
						sx={{
							position: 'relative',
							width: '6px',
							height: '26px',
							display: 'flex',
							alignItems: 'flex-end',
						}}
					>
						<Box
							sx={{
								width: '6px',
								height: log.status ? '26px' : '10px',
								backgroundColor: log.status ? '#5DED55' : '#ED5555',
								border: `1px solid ${log.status ? '#24891B' : '#891B1B'}`,
								borderRadius: '2px',
							}}
						/>
					</Box>
				</OfferToolTip>
			))}
		</Box>
	);
};

export default LogsChart;
