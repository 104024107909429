import { Autocomplete, TextField, Typography } from '@mui/material';
import { usePO } from '../../../../utils/POContext';

import { useTranslation } from 'react-i18next';
import cep from 'cep-promise';
import FormDivider from 'src/components/FormDivider';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import {
	TextMaskCEP,
	TextMaskDynamicRegNumber,
	statesUf,
	TextMaskPhone,
} from 'src/helpers';

import { CountriesType } from 'src/interfaces';
import { Line } from 'src/styles';
import { makeStyles } from '@mui/styles';
import { enqueueSnackbar } from 'notistack';
import { ClientBranchType } from 'src/interfaces/Clients';

interface Props {
	formData: ClientBranchType;
	setFormData: (ClientBranchType) => void;
	formErrors?: any;
	newCompanyBranch: string | null;
	setNewCompanyBranch: (string) => void;
	selectedCountry: any;
	setSelectedCountry: (any) => void;
	translatedCountries?: any;
}

const ClientContactInfo: React.FC<Props> = ({
	formData,
	setFormData,
	formErrors,
	selectedCountry,
	setSelectedCountry,
	translatedCountries,
}: Props) => {
	const { selectedTheme } = usePO();
	const { t } = useTranslation();

	const useStyles = makeStyles(() => ({
		autoCompleteStyle: {
			backgroundColor:
				selectedTheme.id === 'dark' ? selectedTheme?.overlay3dp : '',
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorHigh : '',
		},
		noOptions: {
			color: selectedTheme.id === 'dark' ? selectedTheme.textColorMedium : '',
		},
	}));

	const classes = useStyles();

	// Get Info from CEP
	const handleCepChange = async (event) => {
		const cepValue = event.target.value.replace(/[^0-9]/g, '');
		if (cepValue?.length === 8) {
			try {
				const address = await cep(cepValue);
				if (address.state) {
					const state = statesUf.find((el) => el.uf === address.state);
					setFormData({
						...formData,
						streetAddress: address.street,
						cityName: address.city,
						stateName: state ? state.name : '',
						zipCode: address.cep,
					});
					setSelectedCountry({ name: 'Brazil' });
				}
			} catch (error) {
				enqueueSnackbar(t('Clientes.CEP não encontrado'), {
					variant: 'warning',
				});
			}
		}
	};

	const checkClientRegNumberType = (event) => {
		if (event.target.value.length === 14) {
			setFormData({
				...formData,
				clientRegNumber: 'CPF',
			});
		}
		if (event.target.value.length === 18) {
			setFormData({
				...formData,
				clientRegNumber: 'CNPJ',
			});
		}
		return '';
	};

	const locationForm = () => (
		<>
			<Line
				style={{
					marginTop: 30,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					name="cityName"
					label={t('Clientes.Cidade')}
					value={formData?.cityName}
					onChange={(event) => {
						handleCepChange(event);
						setFormData({ ...formData, cityName: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '60%',
						marginRight: '20px',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
				<TextField
					variant="outlined"
					size="small"
					name="stateName"
					label={t('Clientes.Estado / Província')}
					value={formData?.stateName}
					onChange={(event) => {
						handleCepChange(event);
						setFormData({ ...formData, stateName: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme?.textColorHigh : '',
						},
					}}
					sx={{
						width: '40%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			<Line
				style={{
					marginTop: 20,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					name="zipCode"
					label={t('Clientes.Código Postal')}
					value={formData?.zipCode}
					onChange={handleCepChange}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						inputComponent: TextMaskCEP as any,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '25%',
						marginRight: '20px',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>

				<TextField
					variant="outlined"
					size="small"
					name="streetAddress"
					label={t('Clientes.Logradouro')}
					value={formData?.streetAddress}
					onChange={(event) => {
						handleCepChange(event);
						setFormData({ ...formData, streetAddress: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '75%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			<Line
				style={{
					marginTop: 20,
				}}
			>
				<TextField
					variant="outlined"
					size="small"
					name="numberAddress"
					label={t('Clientes.Número')}
					value={formData?.numberAddress}
					onChange={(event) => {
						setFormData({ ...formData, numberAddress: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '25%',
						marginRight: '20px',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>

				<TextField
					variant="outlined"
					size="small"
					name="complement"
					label={t('Clientes.Complemento')}
					value={formData?.complement}
					onChange={(e) => {
						setFormData({ ...formData, complement: e.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '75%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
		</>
	);

	const contactForm = () => (
		<>
			<TextField
				variant="outlined"
				size="small"
				name="contactName"
				label={t('Clientes.Nome do contato')}
				value={formData?.contactName}
				error={!!formErrors.contactName}
				helperText={formErrors.contactName || ''}
				onChange={(event) => {
					setFormData({ ...formData, contactName: event.target.value });
				}}
				autoComplete="off"
				InputLabelProps={{
					shrink: true,
					style: {
						color: selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
					},
				}}
				InputProps={{
					style: {
						color:
							selectedTheme.id === 'dark' ? selectedTheme?.textColorHigh : '',
					},
				}}
				sx={{
					width: '100%',
					'.MuiFormHelperText-root.Mui-error': {
						mt: '0px',
						color: selectedTheme.error,
						borderColor: selectedTheme.error,
						position: 'absolute',
						bottom: '-21px',
					},
					'& .MuiInputBase-root': {
						background:
							selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
						'& > fieldset': {
							borderColor: selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
						},
						':hover': {
							'& > fieldset': {
								borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
							},
						},
					},
				}}
			/>
			<Line style={{ marginTop: 30, marginBottom: 10 }}>
				<TextField
					variant="outlined"
					size="small"
					name="email1"
					label={t('Clientes.E-mail 1')}
					value={formData?.email1}
					error={!!formErrors.email1}
					helperText={formErrors.email1 || ''}
					onChange={(event) => {
						setFormData({ ...formData, email1: event.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '70%',
						marginRight: '20px',
						'.MuiFormHelperText-root.Mui-error': {
							mt: '0px',
							color: selectedTheme.error,
							borderColor: selectedTheme.error,
							position: 'absolute',
							bottom: '-21px',
						},
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>

				<TextField
					variant="outlined"
					size="small"
					name="phone1"
					label={t('Clientes.Telefone 1')}
					value={formData?.phone1}
					onChange={(e) => {
						setFormData({ ...formData, phone1: e.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						inputComponent: TextMaskPhone as any,
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme?.textColorHigh : '',
						},
					}}
					sx={{
						width: '30%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			<Line style={{ marginTop: 30, marginBottom: 10 }}>
				<TextField
					variant="outlined"
					size="small"
					name="email2"
					label={t('Clientes.E-mail 2')}
					value={formData?.email2}
					error={!!formErrors.email2}
					helperText={formErrors.email2 || ''}
					onChange={(e) => {
						setFormData({ ...formData, email2: e.target.value });
					}}
					autoComplete="off"
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
						},
					}}
					sx={{
						width: '70%',
						marginRight: '20px',
						'.MuiFormHelperText-root.Mui-error': {
							mt: '0px',
							color: selectedTheme.error,
							borderColor: selectedTheme.error,
							position: 'absolute',
							bottom: '-21px',
						},
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
				<TextField
					variant="outlined"
					size="small"
					name="phone2"
					label={t('Clientes.Telefone 2')}
					autoComplete="off"
					value={formData?.phone2}
					onChange={(e) => {
						setFormData({ ...formData, phone2: e.target.value });
					}}
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						inputComponent: TextMaskPhone as any,
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme?.textColorHigh : '',
						},
					}}
					sx={{
						width: '30%',
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
		</>
	);

	return (
		<>
			<FormDivider
				name={t('Clientes.Dados da Unidade')}
				background={
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground
				}
			/>
			<Line style={{ alignItems: 'flex-start' }}>
				<Autocomplete
					id="countrySelect"
					style={{ width: '60%', marginRight: 20 }}
					value={selectedCountry || null}
					options={translatedCountries}
					onChange={(event, newValue) => {
						setSelectedCountry(newValue);
						setFormData({
							...formData,
							countryID: newValue?.id || '',
						});
					}}
					classes={{
						option: classes.autoCompleteStyle,
						paper: classes.autoCompleteStyle,
						noOptions: classes.noOptions,
					}}
					noOptionsText={t('Clientes.Nenhuma opção disponível')}
					autoComplete
					disableClearable
					getOptionLabel={(option) => option.name}
					renderOption={(props, option: CountriesType) => (
						<li {...props}>{option.name}</li>
					)}
					isOptionEqualToValue={(option: CountriesType, value: CountriesType) =>
						option.name === value?.name
					}
					renderInput={(params) => (
						<TextField
							{...params}
							name="countryID"
							size="small"
							label={t('Clientes.País')}
							value={selectedCountry}
							error={!!formErrors.countryID}
							helperText={formErrors.countryID || ''}
							variant="outlined"
							inputProps={{
								...params.inputProps,
								autoComplete: 'new-password', // disable autocomplete and autofill
								style: {
									color:
										selectedTheme.id === 'dark' && selectedTheme?.textColorHigh,
								},
							}}
							InputLabelProps={{
								shrink: true,
								style: {
									color:
										selectedTheme.id === 'dark' &&
										selectedTheme.textColorMedium,
								},
							}}
							sx={{
								'.MuiFormHelperText-root.Mui-error': {
									mt: '0px',
									color: selectedTheme.error,
									borderColor: selectedTheme.error,
									position: 'absolute',
									bottom: '-21px',
								},
								'& .MuiInputBase-root': {
									background:
										selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
									':hover': {
										'& > fieldset': {
											borderColor:
												selectedTheme.id === 'dark' ? '#fff' : '#000',
										},
									},
								},
								'.MuiSvgIcon-root ': {
									fill:
										selectedTheme.id === 'dark' &&
										selectedTheme?.textColorMedium,
								},
								'& .MuiInputBase-root.Mui-disabled': {
									'& > fieldset': {
										borderColor:
											selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
									},
								},
							}}
						/>
					)}
				/>

				<TextField
					variant="outlined"
					size="small"
					name="clientRegNumber"
					value={formData?.clientRegNumber}
					error={!!formErrors?.clientRegNumber}
					helperText={formErrors?.clientRegNumber || ''}
					label={t('Clientes.Número de registro / CNPJ')}
					autoComplete="off"
					onChange={(event) => {
						const newClientRegNumber = event.target.value;
						checkClientRegNumberType(event);
						setFormData({
							...formData,
							clientRegNumber: newClientRegNumber,
						});
					}}
					InputLabelProps={{
						shrink: true,
						style: {
							color:
								selectedTheme.id === 'dark' && selectedTheme.textColorMedium,
						},
					}}
					InputProps={{
						style: {
							color:
								selectedTheme.id === 'dark' ? selectedTheme?.textColorHigh : '',
						},
						inputComponent: TextMaskDynamicRegNumber as any,
						endAdornment: (
							<Typography
								variant="caption"
								style={{
									color:
										cnpj.isValid(formData.clientRegNumber) ||
										cpf.isValid(formData.clientRegNumber)
											? selectedTheme.success
											: selectedTheme.error,
									marginBottom: -2,
								}}
							>
								{formData.clientRegNumber?.toUpperCase() || ''}
							</Typography>
						),
					}}
					sx={{
						width: '40%',
						'.MuiFormHelperText-root.Mui-error': {
							mt: '0px',
							color: selectedTheme.error,
							borderColor: selectedTheme.error,
							position: 'absolute',
							bottom: '-21px',
						},
						'& .MuiInputBase-root': {
							background:
								selectedTheme.id === 'dark' && selectedTheme?.overlay3dp,
							'& > fieldset': {
								borderColor:
									selectedTheme.id === 'dark' ? '#575757' : '#c4c4c4',
							},
							':hover': {
								'& > fieldset': {
									borderColor: selectedTheme.id === 'dark' ? '#fff' : '#000',
								},
							},
						},
					}}
				/>
			</Line>
			{locationForm()}
			<FormDivider
				name={t('Clientes.Informações de Contato')}
				background={
					selectedTheme.id === 'dark'
						? selectedTheme.overlay6dp
						: selectedTheme.foreground
				}
			/>
			{contactForm()}
		</>
	);
};

export default ClientContactInfo;
